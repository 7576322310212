const REQUEST_MESHES = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_MESHES = (state, { body }) => {
  state.Meshes = body;
  state.status = "success";
};

const FAILURE_REQUEST_MESHES = state => {
  state.status = "failure";
};



const REQUEST_LOAD_MESH = state => {
  state.statusLoad = "fetching";
};

const SUCCESS_REQUEST_LOAD_MESH = (state, { body }) => {
  state.Mesh = body;
  state.statusLoad = "success";
};

const FAILURE_REQUEST_LOAD_MESH = state => {
  state.statusLoad = "failure";
};


const REQUEST_DELETE_MESH = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_MESH = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_MESH = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_MESH = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_MESH = (state, {body}) => {
  state.statusUpdate = "success";
  state.Mesh = body;
};

const FAILURE_REQUEST_UPDATE_MESH = state => {
  state.statusUpdate = "failure";
};

const REQUEST_CREATE_MESH = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_MESH = (state, {body}) => {
  state.statusCreate = "success";
  state.Mesh = body;
};

const FAILURE_REQUEST_CREATE_MESH = state => {
  state.statusCreate = "failure";
};

const REQUEST_DEACTIVATE_MESH = state => {
  state.statusDeactivate = "fetching";
};

const SUCCESS_REQUEST_DEACTIVATE_MESH = state => {
  state.statusDeactivate = "success";
};

const FAILURE_REQUEST_DEACTIVATE_MESH = state => {
  state.statusDeactivate = "failure";
};
export default {
  REQUEST_MESHES,
  SUCCESS_REQUEST_MESHES,
  FAILURE_REQUEST_MESHES,
  REQUEST_LOAD_MESH,
  SUCCESS_REQUEST_LOAD_MESH,
  FAILURE_REQUEST_LOAD_MESH,
  REQUEST_CREATE_MESH,
  SUCCESS_REQUEST_CREATE_MESH,
  FAILURE_REQUEST_CREATE_MESH,
  REQUEST_DELETE_MESH,
  SUCCESS_REQUEST_DELETE_MESH,
  FAILURE_REQUEST_DELETE_MESH,
  REQUEST_UPDATE_MESH,
  SUCCESS_REQUEST_UPDATE_MESH,
  FAILURE_REQUEST_UPDATE_MESH,
  REQUEST_DEACTIVATE_MESH,
  SUCCESS_REQUEST_DEACTIVATE_MESH,
  FAILURE_REQUEST_DEACTIVATE_MESH
};
