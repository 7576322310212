import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  status: void 0,
  statusUpdate: void 0,
  statusDelete: void 0,
  statusHistory: void 0,
  statusCreate: void 0,
  statusCreateId: void 0,
  statusCreateFileId: void 0,
  statusDeactivate: void 0,
  statusLoad: void 0,
  Meshes: [],
  Mesh: undefined
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
