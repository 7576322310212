import api from "../../../api";

export default {
  async getMeshes({ commit }) {
    commit("REQUEST_MESHES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_MESHES",
        await api.getMeshes()
      );
    } catch (err) {
      commit("FAILURE_REQUEST_MESHES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async loadMesh({ commit }, id) {
    commit("REQUEST_LOAD_MESH");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_LOAD_MESH",
        await api.loadMesh(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_LOAD_MESH");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteMesh({ commit }, id) {
    commit("REQUEST_DELETE_MESH");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_MESH",
        await api.deleteMesh(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DELETE_MESH");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateMesh({ commit }, item ) {
    commit("REQUEST_UPDATE_MESH");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_MESH",
        await api.updateMesh(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_MESH");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createMesh({ commit }, item ) {
    commit("REQUEST_CREATE_MESH");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_MESH",
        await api.createMesh( item )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_MESH");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deactivateMesh({ commit }, id) {
    commit("REQUEST_DEACTIVATE_MESH");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEACTIVATE_MESH",
        await api.deactivateMesh(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DEACTIVATE_MESH");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
