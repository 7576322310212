import types from "./types";

const REQUEST_SIGNUP = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_SIGNUP = state => {
  state.status = "success";
};

const FAILURE_REQUEST_SIGNUP = state => {
  state.status = "failure";
};

const REQUEST_UPDATE = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE = state => {
  state.statusUpdate = "failure";
};


const REQUEST_USERS = state => {
  state.statusUsers = "fetching";
};

const SUCCESS_REQUEST_USERS = (state, {body}) => {
  state.Users = body;
  state.statusUsers = "success";
};

const FAILURE_REQUEST_USERS = state => {
  state.statusUsers = "failure";
};


const REQUEST_USER = state => {
  state.statusUser = "fetching";
};

const SUCCESS_REQUEST_USER = (state, {body}) => {
  state.User = body;
  state.statusUser = "success";
};

const FAILURE_REQUEST_USER = state => {
  state.statusUser = "failure";
};

export default {
  REQUEST_SIGNUP,
  SUCCESS_REQUEST_SIGNUP,
  FAILURE_REQUEST_SIGNUP,

  REQUEST_UPDATE,
  SUCCESS_REQUEST_UPDATE,
  FAILURE_REQUEST_UPDATE,

  [types.REQUEST_ACTIVATE_ACCOUNT]: state => {
    state.statusAccountActivation = "fetching";
  },
  [types.SUCCESS_REQUEST_ACTIVATE_ACCOUNT]: state => {
    state.statusAccountActivation = "success";
  },
  [types.FAILURE_REQUEST_ACTIVATE_ACCOUNT]: state => {
    state.statusAccountActivation = "failure";
  },
  [types.REQUEST_FORGOT_PASSWORD]: state => {
    state.statusForgotPassword = "fetching";
  },
  [types.SUCCESS_REQUEST_FORGOT_PASSWORD]: (state, resp) => {
    state.statusForgotPassword = "success";
  },
  [types.FAILURE_REQUEST_FORGOT_PASSWORD]: state => {
    state.statusForgotPassword = "failure";
  },
  [types.REQUEST_RESET_PASSWORD]: state => {
    state.statusResetPassword = "fetching";
  },
  [types.SUCCESS_REQUEST_RESET_PASSWORD]: (state, resp) => {
    state.statusResetPassword = "success";
  },
  [types.FAILURE_REQUEST_RESET_PASSWORD]: state => {
    state.statusResetPassword = "failure";
  },

  REQUEST_USERS,
  SUCCESS_REQUEST_USERS,
  FAILURE_REQUEST_USERS,

  REQUEST_USER,
  SUCCESS_REQUEST_USER,
  FAILURE_REQUEST_USER
};
