export default {
  messages: {
    "en": {
      "ok": "OK",
      "Menu":"Menu"    
    },
    "de": {
      "ok": "OK",
      "Menu": "Menü",
      "person": "person",
      "Persons": "Personen",
      "energyUnit": "Energieerzeuger",
      "location": "Location",
      "contract": "Vertrag",
      "Dashboard": "Dashboard",
      "device": "Device",
      "admin": "admin",
      "home": "home",
      "administration": "administration",
      "Sign Up": "Registrieren",
      "Forgot password?": "Passwort vergessen?",
      "You don't have an account yet and you want to sign up?": "Sie haben noch keinen User Zugang und wollen sich registrieren?",
      "Back to Login": "Zurück zum Login",
      "invoice": "Rechnung",
      "Reset password": "Passwort zurücksetzen",
      "Logout": "Abmelden"
    }
  }
}