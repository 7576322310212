<template>
  <div class="mesh">
    <v-card flat v-if="mySelf" dark>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="Meshes"
            sort-by="name"
            class="elevation-1"
            dense
            must-sort
            :footer-props="{'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>STLs</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">New Item</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-file-input v-model="selectedFile" show-size label="New File"></v-file-input>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-autocomplete
                              :items="locationNames"
                              label="Location"
                              v-model="editedItem.location_index"
                              item-text="name"
                              item-value="index"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-autocomplete
                              :items="materials"
                              label="Material"
                              v-model="editedItem.material"
                              item-text="name"
                              item-value="index"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-checkbox v-model="editedItem.enabled" label="Enabled"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";

import { fromBase64, toBase64 } from "../functions/base64_helpers";

export default {
  name: "mesh",
  components: {},
  data() {
    return {
      selectedFile: undefined,
      location_index: 0,
      materials: ["green", "xray", "white", "lightGreen",  "light", "red", "blue"],
      sendObj: {},

      dialog: false,
      headers: [
        {
          text: "name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "location_name", value: "location_name" },
        { text: "material", value: "material" },
        { text: "enabled", value: "enabled" },
        { text: "size", value: "size" },
        { text: "updatedAt", value: "updatedAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      editedIndex: -1,
      editedItem: {
        name: "",
        size: 0,
        material: "",
        location: 0,
        location_index: 0,
        location_name: "",
        enabled: true,
        file_id: undefined,
        _id: undefined,
      },
      defaultItem: {
        name: "",
        size: 0,
        material: "green",
        location: 0,
        location_index: 0,
        location_name: "",
        enabled: true,
        file_id: undefined,
        _id: undefined,
      },

      snackbar: false,
      snackbar_text: "",
    };
  },
  async mounted() {
    await this.getSmApi({});
    await this.getMeshes({});

    for (let mesh of this.Meshes) {
      let obj = this.comp_location(mesh.location);
      //console.log(obj);
      mesh.location_name = obj.name;
      mesh.location_index = obj.index;
      mesh.updatedAt = this.$moment(mesh.updatedAt).format("DD-MM-YYYY HH:mm");
    }
    this.$forceUpdate();
    this.editedItem = Object.assign({}, this.defaultItem);
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Locations: ({ sm_api }) => sm_api.Locations,
      Meshes: ({ mesh }) => mesh.Meshes,
      MeshState: ({ mesh }) => mesh,
    }),

    locationNames() {
      let locnames = [];
      this.Locations.sort((a, b) => {
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
          return 1;
        } else {
          return -1;
        }
      });
      this.Locations.forEach((loc, index) => {
        locnames.push({ name: loc.name, index: index });
      });
      return locnames;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  methods: {
    ...mapActions("mesh", [
      "getMeshes",
      "loadMesh",
      "createMesh",
      "updateMesh",
      "deleteMesh",
      "deactivateMesh",
    ]),
    ...mapActions("sm_api", ["getSmApi"]),

    comp_location(id) {
      try {
        let index = 0;
        let name = this.Locations.find((obj, idx) => {
          index = idx;
          return obj._id == id;
        }).name;
        return { name: name, index: index };
      } catch {}
    },

    async editItem(item) {
      //console.log("editItem");
      this.editedIndex = this.Meshes.indexOf(item);
      this.editedItem = Object.assign({}, item);

      await this.loadMesh(item.file_id);
      const base64File = this.MeshState.Mesh;
      this.selectedFile = await fromBase64(base64File, item.name);

      this.dialog = true;
    },

    async deleteItem(item) {
      const index = this.Meshes.indexOf(item);
      await this.deleteMesh(item._id);
      if (this.MeshState.statusDelete == "success") {
        this.Meshes.splice(index, 1);
      } else {
        this.snackbar_text =
            "Bereits gelöscht oder Server nicht erreichbar";
          this.snackbar = true;
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.selectedFile = undefined;
      });
    },

    async save() {
      //console.log("save");
      let loc = this.Locations[this.editedItem.location_index];
      this.editedItem.location = loc._id;
      this.editedItem.location_name = loc.name;

      try {
        this.editedItem.name = this.selectedFile.name;
        this.editedItem.size = this.selectedFile.size;
        const base64File = await toBase64(this.selectedFile);
        this.editedItem.file = base64File;
      } catch {}

      //console.log(this.editedItem)

      if (this.editedIndex > -1) {
        await this.updateMesh(this.editedItem);
        if (this.MeshState.statusUpdate == "success") {
          this.editedItem._id = this.MeshState.Mesh._id;
          this.editedItem.file_id = this.MeshState.Mesh.file_id;
          this.editedItem.updatedAt = this.$moment(this.MeshState.Mesh.updatedAt).format("DD-MM-YYYY HH:mm");
          Object.assign(this.Meshes[this.editedIndex], this.editedItem);
        } else {
          this.snackbar_text =
            "Filename existiert bereits oder Server nicht erreichbar";
          this.snackbar = true;
        }
      } else {
        await this.createMesh(this.editedItem);
        if (this.MeshState.statusCreate == "success") {
          this.editedItem._id = this.MeshState.Mesh._id;
          this.editedItem.file_id = this.MeshState.Mesh.file_id;
          this.editedItem.updatedAt = this.$moment(this.MeshState.Mesh.updatedAt).format("DD-MM-YYYY HH:mm");
          this.Meshes.push(this.editedItem);
        } else {
          this.snackbar_text =
            "Filename existiert bereits oder Server nicht erreichbar";
          this.snackbar = true;
        }
      }
      this.close();
    },
  },
  watch: {

  },
};
</script>