import {
  AuthResource,
  SignUpResource,
  ActivateAccountResource,
  MySelfResource,
  ForgotPasswordResource,
  ResetPasswordResource,
  UserReadResource,
  UserResource,


  SmApiResource,
  SaveDeviceCoordinatesResource,

  MeshReadResource,

  MeshResource,
  MeshCreateResource,
  MeshDeleteResource,
  MeshUpdateResource,
  MeshDeactivateResource,

  FlowchartResource,
  FlowchartCreateResource,
  FlowchartDeleteResource,
  FlowchartUpdateResource,


} from "./resources";

export default {
  authenticate({ email, password }) {
    return AuthResource.save({}, { email, password });
  },
  mySelf() {
    return MySelfResource.get();
  },
  signUp({ email, password, displayname, fullname }) {
    return SignUpResource.save({}, { email, password, displayname, fullname });
  },
  activateAccount({ token }) {
    return ActivateAccountResource.get({ token }, {});
  },
  forgotPassword({ email }) {
    return ForgotPasswordResource.save({ email });
  },
  resetPassword({ token }) {
    return ResetPasswordResource.save({ token }, {});
  },
  readUser({id}){
    return UserReadResource.get({id}, {});
  },
  getUser(){
    return UserResource.get();
  },
  getSmApi() {
    // console.log("API sm");
    return SmApiResource.get();
  },
  saveDeviceCoordinates(device) {
    return SaveDeviceCoordinatesResource.update({ id: device._id }, device.coordinates);
  },

  createMesh(obj) {
    return MeshCreateResource.save({}, obj);
  },  
  getMeshes() {
    return MeshResource.get();
  },
  loadMesh(id) {
    return MeshReadResource.get({id}, {});
  },
  updateMesh(mesh) {
    return MeshUpdateResource.update({ id: mesh._id }, mesh);
  },
  deleteMesh(id) {
    return MeshDeleteResource.delete({ id }, {});
  },
  deactivateMesh(id) {
    return MeshDeactivateResource.update({ id }, {});
  },

  createFlowchart(obj) {
    return FlowchartCreateResource.save({}, obj);
  },  
  getFlowcharts() {
    return FlowchartResource.get();
  },
  updateFlowchart(obj) {
    return FlowchartUpdateResource.update({ id: obj._id }, obj);
  },
  deleteFlowchart(id) {
    return FlowchartDeleteResource.delete({ id }, {});
  },

  
};
