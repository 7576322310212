import api from "../../../api";

export default {
  async getSmApi({ commit }) {
    commit("REQUEST_SMAPI");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_SMAPI",
        await api.getSmApi({})
      );
    } catch (err) {
      commit("FAILURE_REQUEST_SMAPI");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async saveDeviceCoordinates({ commit }, device) {
    console.log("COOOOORRRDDDDDS", device)
    commit("REQUEST_SAVEDEVICECOORDINATES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_SAVEDEVICECOORDINATES",
        await api.saveDeviceCoordinates(device)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_SAVEDEVICECOORDINATES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
