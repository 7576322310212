//import "@babel/polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
//import "./plugins/vuetify";
import vuetify from "@/plugins/vuetify";
//import Vuetify from 'vuetify/lib'
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import Auth from "./plugins/auth";
//import "./registerServiceWorker";
import "./registerServiceWorker";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
//import VeeValidate from 'vee-validate'
import VueI18n from "vue-i18n";
//import en from 'vee-validate/dist/locale/en.js'
//import de from 'vee-validate/dist/locale/de.js'
import messages from "./language";
//import vb from 'vue-babylonjs';

Vue.config.productionTip = false;

//Vue.use(vb);

Vue.use(VueCompositionApi);

Vue.use(Auth, { router, store });

Vue.use(VueMoment, {
  moment,
});
//Vue.use(VeeValidate)
//Vue.use(draggable)
//Vue.use(Vuetify)
Vue.use(Auth, { router });
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "de",
  messages: messages.messages,
});

/*Vue.use(VeeValidate, {
  i18n,
  dictionary: {
    de, en
  }
})*/

Vue.config.ignoredElements = [
  "a-scene",
  "a-sky",
  "a-light",
  "a-nft",
  "a-assets",
  "a-asset-item",
  "a-marker",
  "a-entity",
  "a-text",
  "a-plane",
  "a-box",
  "a-box",
  "a-image",
  "a-video",
  "a-camera",
  "a-cursor",
  "a-entity",
  "a-cylinder",
  "a-sphere",
];

//require("aframe");

new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
