
export function createAmbientLight(scene) {
  let light = new BABYLON.HemisphericLight("ambient", new BABYLON.Vector3(0, 1, 0), scene);
  light.diffuse = new BABYLON.Color3(0.5, 0.5, 0.5);
  light.specular = new BABYLON.Color3(0, 0, 0);
  light.groundColor = new BABYLON.Color3(0.1, 0.1, 0.1);
  return light;
};

export function createMaterial(scene, uniqueName, r, g, b, alpha) {
  let material = new BABYLON.StandardMaterial(uniqueName, scene);
  material.ambientColor = new BABYLON.Color3(r, g, b);

  if (alpha != null) {
    material.alpha = alpha;
  }

  return material;
};

export function createXrayMaterial(scene, uniqueName) {
  let xrayMaterial = new BABYLON.StandardMaterial(uniqueName, scene);
  xrayMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
  xrayMaterial.alpha = 0.1;

  const fresnel_params = new BABYLON.FresnelParameters();
  fresnel_params.isEnabled = true;
  //fresnel_params.leftColor = new BABYLON.Color3(0.65,0.94,0.97);   //Color 1
  fresnel_params.leftColor = new BABYLON.Color3(0.06, 0.64, 0.81); //Color 2
  fresnel_params.rightColor = new BABYLON.Color3(0, 0, 0);
  fresnel_params.power = 3;
  fresnel_params.bias = 0.1;

  const fresnel_params2 = new BABYLON.FresnelParameters();
  fresnel_params2.isEnabled = true;
  fresnel_params2.leftColor = new BABYLON.Color3(1, 1, 1);
  fresnel_params2.rightColor = new BABYLON.Color3(0.2, 0.2, 0.2);
  fresnel_params2.power = 2;
  fresnel_params2.bias = 0.5;

  xrayMaterial.emissiveFresnelParameters = fresnel_params;
  xrayMaterial.opacityFresnelParameters = fresnel_params2;

  return xrayMaterial;
};

export function tube2D (name, options, scene) {
  let tube = BABYLON.MeshBuilder.CreateTube(name, { path: options.path, radius: options.width, sideOrientation: BABYLON.Mesh.DOUBLESIDE }, scene);
  tube.material = options.material;
  return tube;
}