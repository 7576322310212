<template>
  <div class="home" :style="cssVars">
    
    <div  v-if="loading_data">
      <img class="logo" src="../assets/sit-logo.gif"/>
      <div  class="logo-overlay">
      </div>
      <pre class="logo-console" id="log"></pre>
    </div>

    <v-card flat v-if="mySelf">
      <v-row>
        <v-col cols="12" sm="8" md="8">
          <canvas
            id="replay"
            style="width: 100%; display: block; outline: none"
            touch-action="none"
          ></canvas>
          <div class="logo2">
            <v-img class="mb-3" @click="adminClick = !adminClick" src="../assets/bar.png" :width="logoSize"></v-img>
            <v-img @click="adminClick = !adminClick" src="../assets/sit.png" :width="logoSize"></v-img>
             
          </div>
          <div class="bottom_info">
            <!-- <img src="../assets/pos.png" width='20'/> -->
            <v-icon color="yellow" size='var(--canvas_icon_size)'>mdi-map-marker</v-icon>
            Ihr Standort 
            <v-icon class="ml-5" size='var(--canvas_icon_size)'>mdi-gesture-swipe</v-icon>
            Streichen für Drehen 
            <v-icon class="ml-5" size='var(--canvas_icon_size)'>mdi-gesture-spread</v-icon>
            2 Finger für Zoom
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="ma-0 mt-n4 mt-xs-7 mt-sm-1 pr-8">
          <v-row>
           
            <v-col cols="12" sm="12" style="height: var(--canvas_input_size)">
              <!--<p class="text-h6">Suchen nach</p>-->
              <!-- <v-autocomplete
                ref="autoComp"
                :items="allItems"
                label="People, Companies, Devices, Locations"
                v-model="selectedAllItem"
                item-text="name"
                item-value="_id"
                :search-input="input"
                :allow-overflow="false"
                
              ></v-autocomplete>-->

              <!-- <v-text-field class="ml-4 mt-5"
                dense
                readonly
                label="Search"
                v-model="input">
              </v-text-field>-->
              <v-btn @click="resetScene" class="white black--text mb-3" block style="text-color: black">Neue Suche</v-btn>
              <div class="whitebox" style="font-size: 22px;">
                <v-icon size="35px">search</v-icon>{{ input }}
              </div>

              <v-list
                dense
                flat
                style="height: var(--canvas_list_size)"
                class="overflow-y-auto mt-5"
                x-small
                :subheader="true"
              >
                <v-list-item-group
                  class="py-0 ma-0"
                  v-model="selectedAllItem"
                  color="primary"
                  no-action
                >
                  <v-list-item v-for="(item, i) in filteredAllItems" :key="i">
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 18px;" v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <!--       <v-autocomplete
                :items="locationNames"
                label="Location Nummer"
                v-model="location"
                item-text="name"
                item-value="index"
              ></v-autocomplete>

            <v-autocomplete
                :items="personsPrivat"
                label="Personen"
                v-model="privat"
                item-text="name"
                item-value="index"
              ></v-autocomplete>

              <v-autocomplete
                :items="personsCompany"
                label="Unternehmen"
                v-model="company"
                item-text="name"
                item-value="index"
              ></v-autocomplete>

              <v-autocomplete
                :items="Devices"
                label="Geräte"
                v-model="selected_device"
                item-text="name"
                item-value="_id"
              ></v-autocomplete>
              -->
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="12" sm="12" style="height: 120px; ">
                  <!-- <v-textarea 
                    no-resize
                    height="100px"
                    filled
                    disabled
                    dense
                    v-model="locationInfo"
                  ></v-textarea>-->
                   <v-textarea
                      :value="locationInfo"
                      label="Informationen"
                      rows="4"
                      readonly
                      outlined
                      no-resize
                      class="caption"
                      style="line-height: 0.5rem !important;"
                    ></v-textarea>
                  <!-- {{ locationInfo }} -->
                  <!--  <v-divider></v-divider>
                  <v-textarea
                    no-resize
                    filled
                    disabled
                    dense
                    v-model="description"
                  ></v-textarea>  -->
                </v-col>
               
              </v-row>
            </v-col>
            
            <v-col cols="12" sm="12" class="py-0 ma-0 py-sm-2">
              <!-- <SimpleKeyboard
                @onChange="onChange"
                @onKeyPress="onKeyPress"
                @input="input"
                :theme="theme"
              /> -->
              <div :class="keyboardClass"></div>
            </v-col>
            <v-col cols="12" sm="12" class="ma-0 my-sm-0 pt-0">
              <v-btn small block v-on:click="toggleCam" v-if="showLine">
                <div v-if="activeCamera == 1">Den Weg zeigen</div>
                <div v-else>Auf Übersicht umschalten</div>
              </v-btn>              

              <!--{{ activeCameraLabel }}-->
              <!--<br/><br/>
              <p class="caption">Informationen</p>-->
            </v-col>
            <v-col cols="12" v-if="adminClick">
              <v-row v-if="mySelf.roles.includes('admin')">
                <v-btn class="drawerbutton" large icon v-on:click="$store.state.drawer = !$store.state.drawer">
                  <v-icon color="white">menu</v-icon>
                </v-btn>
                <v-col cols="12">
                  <v-btn color="red" @click="tracking = !tracking"
                    >Tracking {{ tracking }}</v-btn
                  >
                  <v-btn color="cyan" v-on:click="toggleTracker">{{
                    trackerButton
                  }}</v-btn>
                  <div v-if="person_location">
                    {{ person_location.altitute }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="blue"
                    @click="
                      pickedMesh.position.x =
                        pickedMesh.position.x + positioningSteps
                    "
                    >x+</v-btn
                  >
                  <v-btn
                    color="blue"
                    @click="
                      pickedMesh.position.x =
                        pickedMesh.position.x - positioningSteps
                    "
                    >x-</v-btn
                  >
                  <v-btn
                    color="blue"
                    @click="
                      pickedMesh.position.z =
                        pickedMesh.position.z + positioningSteps
                    "
                    >y+</v-btn
                  >
                  <v-btn
                    color="blue"
                    @click="
                      pickedMesh.position.z =
                        pickedMesh.position.z - positioningSteps
                    "
                    >y-</v-btn
                  >
                  <v-btn
                    color="blue"
                    @click="
                      pickedMesh.position.y =
                        pickedMesh.position.y + positioningSteps
                    "
                    >z+</v-btn
                  >
                  <v-btn
                    color="blue"
                    @click="
                      pickedMesh.position.y =
                        pickedMesh.position.y - positioningSteps
                    "
                    >z-</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-if="pickedMesh"
                    class="caption"
                    dense
                    label="X,Y,Z"
                    v-model="pickedMeshCoordsManual"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn color="green" @click="setPickedMeshCoordsManual"
                    >Update Coords</v-btn
                  >
                  <v-btn color="orange" @click="setPosSteps">{{
                    positioningSteps
                  }}</v-btn>
                  <v-btn color="green" @click="saveDevice">Save</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    

    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import api from "../api";
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
//import * as GUI from 'babylonjs-gui';

import { fromBase64 } from "../functions/base64_helpers";
//import SimpleKeyboard from "./SimpleKeyboard";
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

import {
  createAmbientLight,
  createMaterial,
  createXrayMaterial,
  //tube2D,
} from "../functions/babylon_helpers";
/*
import {
  ref,
  computed,
  watch,
  onBeforeMount,
  onMounted,
} from "@vue/composition-api";
*/
//import { FileLoader } from 'three';
import {} from "vrmlparser";

import * as d3 from "d3";

import proj4 from "proj4";

import geolocator from "geolocator";

//import {
//  proj4js
//} from "../functions/proj4_helpers";
// import UndirectedWeightedGraph from '../functions/graph.js'
import graph from "../functions/graph.js";
//import Vue from "vue";

export default {
  name: "Home",
  components: {
   // SimpleKeyboard,
  },
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
  },
  data() {
    return {
      loading_data: true,

      adminClick: false,
      keyboard: null,
      logoSize: "150px",

      canvas: undefined,
      engine: undefined,
      scene: undefined,
      camera: undefined,
      camera2: undefined,
      line: undefined,
      lines: [],
      glow: undefined,
      highlight: undefined,

      activeCamera: 1,
      activeCameraLabel: "Orbit Cam",
      setGlow: 0,
      glowRate: 0.03,
      glowDirection: 1,
      animationInterval: undefined,
      animationIntervalRate: 100,

      showLine: true,
      loaded_meshes: [[], []],
      loaded_devices: [],

      material: {
        alpha: 1,
        white: null,
        red: null,
        green: null,
        lightGreen: null,
        blue: null,
        xray: null,
        light: null,
        transparent: null,
      },

      enabledMeshes: [],
      targetMesh: undefined,

      location_id: undefined,
      is_buildings: 1,

      location: undefined,
      privat: undefined,
      company: undefined,
      Privat: [],
      Company: [],
      locationInfo: "",
      locationInfoStd: "Bitte suchen sie eine Adresse/Namen mit der Tastatur und wählen diese in der oberen Liste aus.",
      description: "",
      selected_device: null,

      snackbar: false,
      snackbar_text: "",

      enabledWrls: [],

      advancedTexture: undefined,
      // pathdemo1: [],
      // path1: [],
      //overlay: undefined
      pickedMesh: undefined,
      multi: 1000.0,
      //this.offset: undefined

      offset: {
        x: -68220,
        y: 4,
        z: 212883.072,
      },

      boxLine: undefined,
      boxText: undefined,

      boxLineOptions: undefined,

      allPathPoints: [],
      pointsShown: [],

      availablePaths: [],
      pathFindingPoints: [],
      linesShortestPath: [],
      pathShape: [
        new BABYLON.Vector3(0, 0, 0),
        //new BABYLON.Vector3(0.2, 0.2, 0),
        new BABYLON.Vector3(0, 0.5, 0),
      ],
      pathShape2: [
        new BABYLON.Vector3(0, 0, 0),
        //new BABYLON.Vector3(0.2, 0.2, 0),
        new BABYLON.Vector3(0.5, 0, 0),
      ],
      pathShape3: [
        new BABYLON.Vector3(0, 0, 0),
        //new BABYLON.Vector3(0.2, 0.2, 0),
        new BABYLON.Vector3(0.2, 0, 0),
      ],

      animationGroup: null,
      animationBox: null,
      animationNormLine: null,
      extrudedPathLine: null,
      positioningSteps: 0.5,

      proj4_GIS: null,
      proj4_GPS: null,
      //loadedMeshes2: []

      geoLocatorOptions: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumWait: 10000, // max wait time for desired accuracy
        maximumAge: 0, // disable cache
        desiredAccuracy: 30, // meters
        fallbackToIP: false, // fallback to IP if Geolocation fails or rejected
        addressLookup: false, // requires Google API key if true
        timezone: false, // requires Google API key if true
        map: undefined, // interactive map element id (or options object)
        staticMap: false, // get a static map image URL (boolean or options object)
      },
      person_location: undefined,
      person_mesh: undefined,

      input: "",
      theme: "hg-theme-default myTheme1",
      selectedAllItem: 0,
      tracking: false,
      trackingInterval: undefined,
      isGettingGeoLocation: false,
      pickedMeshCoordsManual: "",
      altitudeCorrFactor: 388,

      trackerButton: "Sim",
    };
  },

  created() {
    this.proj4_GIS = new proj4.Proj(
      "+proj=tmerc +lat_0=0 +lon_0=16.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs"
    );
    this.proj4_GPS = new proj4.Proj("WGS84");
    this.logoSize = window.innerWidth / 10 + "px";
    this.locationInfo = this.locationInfoStd;
  },

  destroyed() {
    this.IntervalOff();
  },

  async mounted() {

    const old = console.log;
    const logger = document.getElementById('log');
    console.log = function () {
      for (var i = 0; i < arguments.length; i++) {
        if (typeof arguments[i] == 'object') {
            logger.innerHTML += (JSON && JSON.stringify ? JSON.stringify(arguments[i], undefined, 2) : arguments[i]) + '<br />';
        } else {
            logger.innerHTML += arguments[i] + '<br />';
        }
      }
    }

    console.log("Home mounted");

    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      theme: this.theme,
      layout: {
        // default: [
        //   "q w e r t y u i o p {bksp}",
        //   "a s d f g h j k l {enter}",
        //   "{shift} z x c v b n m , . {shift}",
        //   "{alt} {space} {altright} {downkeyboard}"
        // ],
        default: [
          "1 2 3 4 5 6 7 8 9 0 {bksp}",
          "Q W E R T Z U I O P",
          "A S D F G H J K L",
          "Y X C V B N M",
        ]
      },
      display: {
        "{alt}": ".?123",
        "{smileys}": "\uD83D\uDE03",
        "{shift}": "⇧",
        "{shiftactivated}": "⇧",
        "{enter}": "return",
        "{bksp}": "⌫",
        "{altright}": "123",
        "{downkeyboard}": "🞃",
        "{space}": " ",
        "{default}": "ABC",
        "{back}": "⇦",
        "{new}": "clear"
      }
    });
    await this.getSmApi({});
    console.log("API connected");
    /*for (let per of this.Persons) {
      per.locationname = this.Locations.find(obj => obj._id = per.location);
    }*/
    this.Privat = JSON.parse(JSON.stringify(this.Persons));
    this.Company = JSON.parse(JSON.stringify(this.Persons));

    await this.getMeshes({});
    console.log("Meshes loaded");

    this.initScene();
    this.initData();
    this.initEventListener();
    this.initCanvasHeight();
    console.log("Init succesful");

    await this.getEnabledMeshes();
    console.log("Got enabled Meshes");

    this.renderScene();
    console.log("Scene rendered");

    this.startAnimationInterval();
    console.log("Animation startet");
    //this.getGeoLocation();
    this.tracking = true;

    //this.drawDevices();
    this.animationGroup = new BABYLON.AnimationGroup("Group");
    //this.drawGeoLocation();

    //this.scene.debugLayer.show();
    console.log("Load UI");
    this.loading_data = false;

  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      //Drawer: (state) => state.drawer,
      // Drawer: {
      //   get() {
      //     return this.$store.state.drawer;
      //   },
      //   set(newValue) {
      //     return this.$store.dispatch("state/setDrawer", newValue);
      //   },
      // },
      Meshes: ({ mesh }) => mesh.Meshes,
      Mesh: ({ mesh }) => mesh.Mesh,
      Global: ({ sm_api }) => sm_api.Global,
      Locations: ({ sm_api }) => sm_api.Locations,
      Persons: ({ sm_api }) => sm_api.Persons,
      Devices: ({ sm_api }) => sm_api.Devices,
    }),

    cssVars() {          
      if (this.canvas) {
        let input_size;
        if (window.innerWidth < 600) {
          input_size = window.innerHeight - this.canvas.height - 320;
        } else {
          input_size = window.innerHeight - 320;
        }
        
        return {
          '--canvas_height': (this.canvas.height - (this.canvas.width / 30)) + 'px',
          '--canvas_font_size': this.canvas.width / 50 + 'px',
          '--canvas_icon_size': this.canvas.width / 30 + 'px',
          '--canvas_input_size': input_size + 'px',
          '--canvas_list_size': input_size / 3 + 'px',
        }
      } else {
        return {
          '--canvas_height': 2000 + 'px',
          '--canvas_font_size': '10px',
          '--canvas_icon_size': '1px',
          '--canvas_input_size': '250px',
          '--canvas_list_size': '130px',
        }
      }
      
    },

    locationNames() {
      let locnames = [];
      this.Locations.sort((a, b) => {
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
          return 1;
        } else {
          return -1;
        }
      });
      this.Locations.forEach((loc, index) => {
        if (this.Meshes.find((obj) => loc._id == obj.location)) {
          locnames.push({ _id: loc._id, name: loc.name, index: index });
        }
      });
      return locnames;
    },
    personsPrivat() {
      let privat = [];
      this.Privat.sort((a, b) => {
        if (a.lastname.toUpperCase() > b.lastname.toUpperCase()) {
          return 1;
        } else {
          return -1;
        }
      });
      this.Privat.forEach((per, index) => {
        if (
          this.Meshes.find((obj) => per.location == obj.location) &&
          per.lastname != ""
        ) {
          privat.push({
            _id: per._id,
            name: (per.lastname + " " + per.firstname).slice(0, 32),
            index: index,
          });
        }
      });
      return privat;
    },
    personsCompany() {
      let company = [];
      this.Company.sort((a, b) => {
        if (a.companyname.toUpperCase() > b.companyname.toUpperCase()) {
          return 1;
        } else {
          return -1;
        }
      });
      this.Company.forEach((per, index) => {
        if (
          this.Meshes.find((obj) => per.location == obj.location) &&
          per.companyname != ""
        ) {
          company.push({
            _id: per._id,
            name: per.companyname.slice(0, 32),
            index: index,
          });
        }
      });
      return company;
    },
    allItems() {
      let allNames = [];

      this.locationNames.forEach((loc, index) => {
        allNames.push({ _id: loc._id, name: loc.name, type: "locationNames" });
      });
      this.personsPrivat.forEach((per, index) => {
        allNames.push({ _id: per._id, name: per.name, type: "personsPrivat" });
      });
      this.personsCompany.forEach((per, index) => {
        allNames.push({ _id: per._id, name: per.name, type: "personsCompany" });
      });
      this.Devices.forEach((device, index) => {
        allNames.push({ _id: device._id, name: device.name, type: "Devices" });
      });
      return _.sortBy(allNames, "name");
    },

    filteredAllItems() {
      let arr = [];

      if (this.input.length > 0) {
        let filter = _.filter(this.allItems, (obj) => {
          return obj.name.toLowerCase().includes(this.input.toLowerCase());
        });
        for (let i = 0; i < filter.length; i++) {
          arr.push(filter[i]);
        }
      }

      return arr;
    },
  },
  methods: {
    ...mapActions("mesh", ["getMeshes", "loadMesh"]),
    ...mapActions("sm_api", ["getSmApi", "saveDeviceCoordinates"]),



    IntervalOff() {
      try {
        if (!_.isUndefined(this.trackingInterval)) {
          clearInterval(this.trackingInterval);
          this.trackingInterval = undefined;
        }
      } catch (e) {
        //
      }
    },

    initCanvasHeight() {
      if (window.innerWidth < 600) {
        this.canvas.height = window.innerHeight * 0.39;
      } else {
        this.canvas.height = window.innerHeight - 2;
      }
      //console.log(this.canvas.height);
    },

    onChange(input) {
      this.input = input;
    },

    async resetScene() {
      this.keyboard.clearInput();
      this.input = "";
      this.locationInfo = this.locationInfoStd;
      this.pickedMesh = undefined;
      this.camera.position = new BABYLON.Vector3(-68240, 40, 212953.072);
      this.camera.setTarget(new BABYLON.Vector3(this.offset.x, this.offset.y, this.offset.z));
      this.camera2.position = new BABYLON.Vector3(-68220, 1.7, 212903.072);
      this.camera2.setTarget(new BABYLON.Vector3(this.offset.x, this.offset.y, this.offset.z));
      if (this.activeCamera == 2) {
        this.toggleCam();
      }
      this.stopAnimation();
        //console.log("Watch Location " + now + " " + last);
        this.privat = undefined;
        this.company = undefined;
        this.selected_device = undefined;
        this.location_id = null;
        this.description = "";
        await this.getEnabledMeshes();
        this.renderScene();
    },

    onKeyPress(button) {  
      if (button == "{new}") {
         this.resetScene();
      }
      //this.$refs["autoComp"].focus();
      //this.$refs["autoComp"].activateMenu();
    },
    onInputChange(input) {
      this.input = input.target.value;
    },

    initScene() {
      this.canvas = document.getElementById("replay");
      this.engine = new BABYLON.Engine(this.canvas, true);
      this.scene = new BABYLON.Scene(this.engine);
      //this.glow = new BABYLON.GlowLayer("glow", this.scene);
      //this.highlight = new BABYLON.HighlightLayer("hl1", this.scene);

      // let this.offset = {
      //   x: -681.856365737447,
      //   y: 0.5,
      //   z: 2151.133646530827,
      // };
      // let this.offset = {
      //   x: -68220,
      //   y: 4,
      //   z: 212883.072,
      // };

      //this.this.offset = this.offset;
      // Add a camera to the scene and attach it to the canvas
      this.camera = new BABYLON.ArcRotateCamera(
        "Orbit",
        Math.PI / 2,
        Math.PI / 2,
        2,
        BABYLON.Vector3.Zero(),
        this.scene
      );
      this.camera.position = new BABYLON.Vector3(-68240, 40, 212953.072);
      this.camera.setTarget(new BABYLON.Vector3(this.offset.x, this.offset.y, this.offset.z));

      this.camera2 = new BABYLON.UniversalCamera(
        "FirstPerson",
        new BABYLON.Vector3(this.offset.x, this.offset.y, this.offset.z),
        this.scene
      );
      this.camera2.fov = Math.PI / 3;
      this.camera2.speed = 0.1;
      this.camera2.fov = 1;
      this.camera2.position = new BABYLON.Vector3(-68220, 1.7, 212903.072);
      this.camera2.setTarget(new BABYLON.Vector3(this.offset.x, this.offset.y, this.offset.z));

      this.camera.minX = 0.1;
      this.camera.minY = 0.1;
      this.camera.minZ = 0.1;
      this.camera.maxX = this.multi;
      this.camera.maxY = this.multi;
      this.camera.maxZ = this.multi;

      this.camera.lowerRadiusLimit = 20;
      this.camera.upperRadiusLimit = 350;

      //this.camera.lowerAlphaLimit = 1;
      //this.camera.upperAlphaLimit = 2;
      this.camera.lowerBetaLimit = 0.1;
      this.camera.upperBetaLimit = Math.PI / 2;

      //this.camera.attachControl(this.canvas, true);

      this.light = createAmbientLight(this.scene);

      //this.scene.activeCamera.useFramingBehavior = true;
      this.scene.ambientColor = new BABYLON.Color3(1, 1, 1);
      this.scene.clearColor = new BABYLON.Color3(37 / 255, 37 / 255, 37 / 255);

      this.scene.activeCamera = this.camera;
      this.camera.attachControl(this.canvas, true);
      this.light.parent = this.camera;

      //this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("ui",true,this.scene);

      this.initRenderLoops();

      let overlay = document.createElement("overlay");
      overlay.id = "overlay";
      document.body.appendChild(overlay);

      //this.scene.debugLayer.show();

      //let ground = BABYLON.Mesh.CreateGround("ground1", 600000, 600000, 2, this.scene);
      // let box = BABYLON.MeshBuilder.CreateBox(
      //   "ground",
      //   {width: 1000, height: -0.1, depth: 1000},
      //   this.scene
      // );
      // box.position = new BABYLON.Vector3(
      //   this.offset.x,
      //   0,
      //   this.offset.z
      // );
      //box.visibility = 0.5;

      // console.log(box)
      //ground.material =  new BABYLON.GridMaterial("groundMaterial", this.scene);
      // let gridMaterial = new BABYLON.StandardMaterial("Grid Material", this.scene);
      // gridMaterial.wireframe = true;
      // box.material = gridMaterial;

      this.scene.registerBeforeRender(() => {
        this.renderOverlay();
      });

      //this.showWorldAxis(100, this.offset);
    },

    initData() {
      this.material.fullRed = createMaterial(this.scene, "fullRed", 1, 0, 0, 1);
      this.material.transparent = createMaterial(
        this.scene,
        "transparentMaterial",
        0,
        0,
        0,
        0
      );
      this.material.white = createMaterial(
        this.scene,
        "whiteMaterial",
        0.5,
        0.5,
        0.5,
        0.6
      );
      /* this.material.white = createMaterial(
        this.scene,
        "whiteMaterial",
        0.5,
        0.5,
        0.5,
        0.5
      );*/
      this.material.red = createMaterial(
        this.scene,
        "redMaterial",
        0.5,
        0,
        0,
        this.alpha
      );
      this.material.green = createMaterial(
        this.scene,
        "greenMaterial",
        0,
        0.5,
        0,
        this.alpha
      );
      this.material.lightGreen = createMaterial(
        this.scene,
        "lightGreenMaterial",
        0,
        0.8,
        0,
        this.alpha
      );
      this.material.blue = createMaterial(
        this.scene,
        "blueMaterial",
        0,
        0,
        0.5,
        this.alpha
      );
      this.material.yellow = createMaterial(
        this.scene,
        "yellowMaterial",
        1,
        1,
        0,
        1
      );
      this.material.xray = createXrayMaterial(this.scene, "xrayMaterial");
      this.material.light = createMaterial(
        this.scene,
        "lightMaterial",
        0.5,
        0.5,
        0.5,
        0.1
      );
    },

    findParent(p_id) {
      return this.Locations.find((e) => e._id == p_id);
    },

    async getEnabledMeshes() {
      //console.log("Get Enabled Meshes");
      let enabled_array = [];

      if (!this.is_buildings) {
        //load parents && elevators ,no buildings
        try {
          let loc = this.Locations.find((obj) => this.location_id == obj._id);
          loc.type_name = this.Global.locationTypes.find(
            (e) => e._id == loc.type
          ).value;
          //console.log(loc.name + " / " + loc.type_name);

          enabled_array.push(loc._id);

          while (loc.type_name != "stairs") {
            loc = this.findParent(loc.parent);
            loc.type_name = this.Global.locationTypes.find(
              (e) => e._id == loc.type
            ).value;
            enabled_array.push(loc._id);

            //elevator child of stairs
            if (loc.type_name == "stairs") {
              let childs = this.Locations.filter((e) => e.parent == loc._id);
              let elevator_type = this.Global.locationTypes.find(
                (e) => e.value == "elevator"
              )._id;
              let elevator = childs.find((e) => e.type == elevator_type);
              if (!_.isUndefined(elevator)) {
                enabled_array.push(elevator._id);
              } else {
                console.log("No Elevator found!");
              }
            }
          }
        } catch (e) {
          //this.snackbar_text = "Objekt konnte nicht gefunden werden";
          //this.snackbar = true;
        }
      } else {
        //load buildings
        let site_id = this.Global.locationTypes.find(
          (e) => e.value == "site"
        )._id;
        let site = this.Locations.find((e) => e.type == site_id);
        //console.log(site._id);

        let childs = this.Locations.filter((e) => e.parent == site._id);
        //console.log(childs);

        let building_type = this.Global.locationTypes.find(
          (e) => e.value == "building"
        )._id;
        let buildings = childs.filter((e) => e.type == building_type);
        for (let building of buildings) {
          enabled_array.push(building._id);
        }
      }

      for (let mesh of this.loaded_meshes[this.is_buildings]) {
        mesh.dispose();
      }

      this.enabledMeshes = [];
      this.enabledWrls = [];

      for (let mesh of this.Meshes) {
        if (enabled_array.includes(mesh.location)) {
          if (mesh.enabled) {
            await this.loadMesh(mesh.file_id);
            mesh.file = this.Mesh;
            if (mesh.name.includes(".stl")) {
              this.enabledMeshes.push(mesh);
            } else if (mesh.name.includes(".wrl")) {
              this.enabledWrls.push(mesh);
            }
          }
        }
      }
    },

    getClientRect(mesh) {
      // get bounding box of the mesh
      const meshVectors = mesh.getBoundingInfo().boundingBox.vectors;

      // get the matrix and viewport needed to project the vectors onto the screen
      const worldMatrix = mesh.getWorldMatrix();
      const transformMatrix = this.scene.getTransformMatrix();
      const viewport = this.scene.activeCamera.viewport;

      // loop though all the vectors and project them against the current camera viewport to get a set of coordinates
      const coordinates = meshVectors.map((v) => {
        const proj = BABYLON.Vector3.Project(
          v,
          worldMatrix,
          transformMatrix,
          viewport
        );
        proj.x = proj.x * this.canvas.clientWidth;
        proj.y = proj.y * this.canvas.clientHeight;
        return proj;
      });

      // get the min and max for all the coordinates so we can calculate the largest possible screen size
      // using d3.extent
      const [minX, maxX] = d3.extent(coordinates, (c) => c.x);
      const [minY, maxY] = d3.extent(coordinates, (c) => c.y);

      // return a ClientRect from this
      const rect = {
        width: maxX - minX,
        height: maxY - minY,
        left: minX,
        top: minY,
        right: maxX,
        bottom: maxY,
      };

      return rect;
    },

    initRenderLoops() {
      // Register a render loop to repeatedly render the scene
      this.engine.runRenderLoop(() => {
        this.scene.render();
      });

      this.scene.onPointerDown = (evt, pickResult) => {
        if (pickResult.hit) {
          this.pickedMesh = pickResult.pickedMesh;
          //console.log("pickedMesh ", pickResult.pickedMesh);
          let coords = {};
          coords.x = pickResult.pickedMesh.position.x;
          coords.y = pickResult.pickedMesh.position.y;
          coords.z = pickResult.pickedMesh.position.z;
          this.pickedMeshCoordsManual = JSON.stringify(coords);

          /*const cW = this.pickedMesh.getBoundingInfo().boundingBox.centerWorld;
          console.log(cW.x, cW.y, cW.z);
          const bB = this.pickedMesh.getBoundingInfo().boundingBox;
          //console.log(bB);
          let source = new proj4.Proj("+proj=tmerc +lat_0=0 +lon_0=16.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs");
          let dest = new proj4.Proj('WGS84'); 
          let nav = proj4.transform(source, dest, [cW.x,cW.z]);  
          console.log("N,E: ", nav);
          let gis = proj4.transform(dest, source, [nav.x,nav.y]);  
          console.log("GIS: ", gis);*/

          //this.targetMesh.getBoundingInfo().boundingBox.center
          /*    const overlay = document.getElementById('overlay');
          //const rect = this.getClientRect(pickedMesh);
          //console.log("Rect: ", rect);
          const newEntity = pickedMesh;
          const meshWrapper = ((newEntity != null) && (newEntity.meshWrapper != null) ? newEntity.meshWrapper : null);
          if ((meshWrapper != null) && (overlay != null)) {
            const rect = meshWrapper.getClientRect();
            const center = (rect.left + rect.right) / 2;
            overlay.style.left = center - (overlay.offsetWidth / 2) + 'px';
            overlay.style.top = (rect.top - overlay.offsetHeight - 20) + 'px';
            updated = true;
          }*/

          // GUI
          // let plane = BABYLON.Mesh.CreatePlane("plane", 2);
          // plane.parent = pickedMesh;
          // plane.position.y = 2;
          //this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI",true,this.scene);
          // this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
          // this.advancedTexture = GUI.AdvancedDynamicTexture.CreateForMesh(
          //    pickedMesh
          // );

          //this.advancedTexture.idealWidth = 600;
          //var ground = BABYLON.Mesh.CreateGround("ground1", 6, 6, 2, this.scene);
          //let selectedRect = this.getClientRect(pickedMesh);
          //var sphere = BABYLON.Mesh.CreateSphere("sphere1", 1, selectedRect.top, this.scene);
          //console.log(advancedTexture.rootContainer);
          // let rect1 = new GUI.Rectangle("rect1");

          // rect1.width = 0.2;
          // rect1.height = "40px";
          // rect1.cornerRadius = 20;
          // rect1.color = "Orange";
          // rect1.thickness = 4;
          // rect1.background = "black";
          // rect1.linkWithMesh(pickedMesh);
          // rect1.linkOffsetY = -150;
          // this.advancedTexture.addControl(rect1);

          // let label = new GUI.TextBlock();
          // label.text = "Sphere";
          // rect1.addControl(label);

          // let target = new GUI.Ellipse();
          // target.width = "40px";
          // target.height = "40px";
          // target.color = "Orange";
          // target.thickness = 4;
          // target.background = "green";
          // target.linkWithMesh(pickedMesh);
          // advancedTexture.addControl(target);

          // let line = new GUI.Line();
          // line.lineWidth = 4;
          // line.color = "Orange";
          // line.y2 = 20;
          // line.linkOffsetY = -20;

          // line.linkWithMesh(pickedMesh);
          // line.connectedControl = rect1;
          // advancedTexture.addControl(line);
          //

          // var utilLayer = new BABYLON.UtilityLayerRenderer(this.scene);
          // // Create an overlay box
          // var overlayBox = BABYLON.Mesh.CreateBox("box", 1, utilLayer.utilityLayerScene);
          // let _coords = pickedMesh.getBoundingInfo().boundingBox;
          // //console.log(pickedMesh.getBoundingInfo().boundingBox);

          // //console.log(rect);
          // overlayBox.position.x = _coords.centerWorld.x ;
          // overlayBox.position.y = _coords.maximumWorld.y * 1.2;
          // overlayBox.position.z = _coords.centerWorld.z ;
          // // Create a different light for the overlay scene
          // var overlayLight = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 0, 1), utilLayer.utilityLayerScene);
          // overlayLight.intensity = 0.7;
          // utilLayer.render();

          //var sphere = BABYLON.Mesh.CreateSphere("sphere1", 10, 10000, this.scene);

          // sphere.position.x = _coords.x;
          // sphere.position.y = _coords.y;
          // sphere.position.z = _coords.z;
          // this.camera.setTarget(
          // new BABYLON.Vector3(
          //   sphere.position.x,
          //    sphere.position.y,
          //    sphere.position.z
          // )
          //);
          //sphere.
        } else {
          this.pickedMesh = null;
        }
      };
    },

    renderOverlay() {
      try {
        let overlay = document.getElementById("overlay");
        if (this.pickedMesh) {
          let rect = this.getClientRect(this.pickedMesh);      
          //console.log(overlay);
          overlay.style =
            "background-color:black; border:solid thick darkblue; border-radius: 0em; border-width:2px; padding-left:9px; padding-top:6px; padding-bottom:6px; margin:2px; width:150px;";
          overlay.style.position = "absolute";
          const center = (rect.left + rect.right) / 2;
          //overlay.style.top = meshPositionOnWindow.y + 'px';
          //overlay.style.left = meshPositionOnWindow.x + 'px';
          // overlay.style.left = center - (overlay.offsetWidth / 2) + 'px';
          overlay.style.top = (rect.top + rect.bottom) / 2 + "px";
          overlay.style.left = center - overlay.offsetWidth / 2 + "px";
          // overlay.style.top = (rect.) + 'px';
          overlay.style.color = "white";
          //overlay.style["font-size"] = "8px";
          overlay.style.font = "12px Arial";
          //console.log("this.pickedMesh.name ",this.pickedMesh.name)
          let pickedDevice = _.find(this.Devices, {
            device_id: this.pickedMesh.name,
          });
          //console.log("pickedDevice ",pickedDevice)
          try {
            overlay.innerHTML = pickedDevice.name;
            overlay.innerHTML += "<br>" + pickedDevice.device_id;
            overlay.innerHTML += "<br>Status: " + pickedDevice.status.status;
          } catch (err) {
            //console.log(err)
          }
          //overlay.innerHTML += "\n" + JSON.stringify(pickedDevice.debug);

          //overlay.style='border:solid thick darkblue; border-radius: 1em; border-width:3px; padding-left:9px; padding-top:6px; padding-bottom:6px; margin:2px; width:100px;'

          //console.log(overlay)

          /*const makeTextPlane = (text, color, size) => {
          
          let dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", 50, this.scene, true);
          dynamicTexture.hasAlpha = true;
          dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color , "transparent", true);
          let plane = BABYLON.Mesh.CreatePlane("TextPlane", size, this.scene, true);
          plane.material = new BABYLON.StandardMaterial("TextPlaneMaterial", this.scene);
          plane.material.backFaceCulling = false;
          plane.material.specularColor = new BABYLON.Color3(0, 0, 0);
          plane.material.diffuseTexture = dynamicTexture;
          return plane;
        };*/

          //const cW = this.pickedMesh.getBoundingInfo().boundingBox.centerWorld;

          /*if (!this.boxText) {
          console.log("Erstelle BoxText");
          this.boxText = makeTextPlane(this.pickedMesh.name, "red", 5);
          this.boxText.position = new BABYLON.Vector3(cW.x, cW.y, cW.z);
        } else {
          this.boxText.position = new BABYLON.Vector3(cW.x, cW.y, cW.z);
          this.boxText.oria
        }*/

          /*if (!this.boxLine) {
          this.boxLineOptions =  [ 
            new BABYLON.Vector3(cW.x, cW.y, cW.z), 
            new BABYLON.Vector3(cW.x + 10, cW.y, cW.z)
          ];
          console.log("Erstelle BoxLine");
          this.boxLine = BABYLON.Mesh.CreateLines("boxLine", this.boxLineOptions , this.scene, true);
          this.boxLine.color = new BABYLON.Color3(1, 1, 0);
          console.log(this.boxLine)
        } else {
          //this.boxLine.position()
          //console.log(this.boxLineOptions[0].x);
          //this.boxLineOptions[0]._x += 60;
          //console.log(this.boxLine.getVerticesData(BABYLON.VertexBuffer.PositionKind));
          //let pos = this.boxLine.getVerticesData(BABYLON.VertexBuffer.PositionKind);
          //pos[0] += 100;
          //console.log(pos);
          this.boxLine.dispose();
          this.boxLineOptions[1].x += ;
          this.boxLine = BABYLON.Mesh.CreateLines("boxLine", this.boxLineOptions , this.scene, true);
        }*/
        } else if (overlay.hasAttribute("style")) {
          overlay.removeAttribute("style");
          overlay.innerHTML = "";
        }
        
      } catch (err) {
        console.log("Overlay Error: ", err);
      }
    },

    async renderScene() {
      //console.log("Scene render");
      let f = 1 / this.multi;

      for (let i = 0; i < this.enabledMeshes.length; ++i) {
        const base64File = this.enabledMeshes[i].file;
        const filenameFile = this.enabledMeshes[i].name;
        const file = await fromBase64(base64File, filenameFile);
        let material = this.material[this.enabledMeshes[i].material];

        const url = window.URL.createObjectURL(file);

        let isTarget = false;
        if (this.enabledMeshes[i].location == this.location_id) {
          isTarget = true;
        }
        //this.loadedMeshes2 = [];
        BABYLON.SceneLoader.ImportMesh(
          "",
          "",
          url,
          this.scene,
          (meshes, particleSystems, skeletons) => {
            for (let j = 0; j < meshes.length; ++j) {
              //console.log("Getting Mesh: " + j);
              meshes[j].scaling = new BABYLON.Vector3(f, f, f);
              meshes[j].material = material;
              meshes[j].flipFaces(false);
              meshes[j].setEnabled(true);
              meshes[j].name = filenameFile;
              meshes[j].isTarget = isTarget;
              meshes[j].isPickable = false;
              //this.loadedMeshes2.push(meshes[j]);
            }

            this.loadedCallback(meshes);
          },
          undefined,
          undefined,
          ".stl"
        );
      }
    },

    loadedCallback(meshes) {
      //console.log(meshes);
      //console.log("Meshes loaded ", meshes[0].isTarget);
      if (meshes[0].isTarget) {
        //ToDo: Array of Target Meshes
        this.targetMesh = meshes[0];
        let center = this.targetMesh.getBoundingInfo().boundingBox.center;
        //console.log("Center: ", center);
        this.generateShortestPath();
        this.camera.setTarget(
          new BABYLON.Vector3(
            center.x / this.multi,
            center.y / this.multi,
            center.z / this.multi
          )
        );
        this.camera2.setTarget(
          new BABYLON.Vector3(
            center.x / this.multi,
            center.y / this.multi,
            center.z / this.multi
          )
        );
        //this.glow.addIncludedOnlyMesh(meshes[0])
      } else {
        //console.log("UNSET GLOW " + meshes[0]);
        //this.glow.addExcludedMesh(meshes[0]);
      }
      this.loaded_meshes[this.is_buildings] =
        this.loaded_meshes[this.is_buildings].concat(meshes);
      if (
        this.loaded_meshes[this.is_buildings].length ==
        this.enabledMeshes.length
      ) {
        console.log("Finished loading.");
        //Switch to sublevels
        if (this.is_buildings) this.is_buildings = 0;

        /*let framingBehavior = this.scene.activeCamera.getBehaviorByName(
          "Framing"
        );
        framingBehavior.framingTime = 0;
        framingBehavior.elevationReturnTime = -1;
        */
        //let worldExtends = this.scene.getWorldExtends();
        //framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max);

        if (this.showLine) {
          //console.log(worldExtends.min.toString(), worldExtends.max.toString());
          /*let this.offset = {
            x: -678.856365737447,
            y: -0.006461070449059747,
            z: 2154.133646530827,
          };
          let e = 0.001;
          let path = [
            new BABYLON.Vector3(this.offset.x - 3, this.offset.y - e, this.offset.z + -1.0),
            new BABYLON.Vector3(this.offset.x + 0, this.offset.y - e, this.offset.z + -1.0),
            new BABYLON.Vector3(this.offset.x + 0.95, this.offset.y - e, this.offset.z + 0.0),
            new BABYLON.Vector3(this.offset.x + 0.95, this.offset.y - e, this.offset.z + 0.76),
            new BABYLON.Vector3(this.offset.x + 0.55, this.offset.y - e, this.offset.z + 0.76),
            new BABYLON.Vector3(this.offset.x + 0.55, this.offset.y - e, this.offset.z + 1.06),
            new BABYLON.Vector3(this.offset.x + 0.2, this.offset.y - e, this.offset.z + 1.06),
            new BABYLON.Vector3(
              this.offset.x + 0.2,
              this.offset.y - e + 0.645,
              this.offset.z + 1.06
            ),
            new BABYLON.Vector3(
              this.offset.x + 0.48,
              this.offset.y - e + 0.645,
              this.offset.z + 1.06
            ),
            new BABYLON.Vector3(
              this.offset.x + 0.48,
              this.offset.y - e + 0.645,
              this.offset.z + 0.95
            ),
          ];
          let path2 = [
            new BABYLON.Vector3(this.offset.x - 3, this.offset.y - e, this.offset.z + -1.0),
            new BABYLON.Vector3(this.offset.x - 4, this.offset.y - e, this.offset.z + 0.0),
            new BABYLON.Vector3(
              this.offset.x - 4,
              this.offset.y - e + 0.1,
              this.offset.z + 1.0
            ),
            new BABYLON.Vector3(this.offset.x - 5.2, this.offset.y - e, this.offset.z + 1.0),
            new BABYLON.Vector3(this.offset.x - 5.2, this.offset.y - e, this.offset.z + 0.6),
            new BABYLON.Vector3(this.offset.x - 4.9, this.offset.y - e, this.offset.z + 0.6),
            new BABYLON.Vector3(
              this.offset.x - 4.9,
              this.offset.y - e + 0.64,
              this.offset.z + 0.6
            ),
            new BABYLON.Vector3(
              this.offset.x - 5.15,
              this.offset.y - e + 0.64,
              this.offset.z + 0.6
            ),
            new BABYLON.Vector3(
              this.offset.x - 5.15,
              this.offset.y - e + 0.64,
              this.offset.z + 1.1
            ),
          ];*/
          //let path = this.path1;
          // console.log("PATHs");
          // console.log(this.path1);
          // console.log(path);
          // console.log(path2);
          // this.line = tube2D("line", { path: path, width: 0.01 }, this.scene);
          // this.line.material = this.material.red;

          // this.line = tube2D("line", { path: path2, width: 0.01 }, this.scene);
          // this.line.material = this.material.green;

          this.enabledWrls.forEach((wrl) => {
            //console.log(wrl)
            const base64Slice = new Buffer.from(wrl.file, "base64");
            const wrlEncoded = base64Slice.toString();
            const material = this.material[wrl.material];
            //console.log("WRL: ", wrlEncoded);
            //console.log("WRL Mat: ", material);
            const tree = vrmlParser.parse(wrlEncoded);
            //console.log("xhrLoader ",tree);
            //wrl.name = '3D_Objekt_14_Zugang_Top_1422';
            wrl.name = wrl.name.replaceAll(" ", "_");
            wrl.name = wrl.name.replaceAll(".wrl", "");

            if (!tree.nodeDefinitions[wrl.name]) {
              wrl.name = "_" + wrl.name;
            }
            //console.log(wrl.name);
            try {
              const len = tree.nodeDefinitions[wrl.name].children.length - 1;
              const objects =
                tree.nodeDefinitions[wrl.name].children[len].children[0]
                  .children;

              let paths = [];
              let lastPoint = new BABYLON.Vector3(0, 0, 0);
              for (let obj of objects) {
                let points = obj.children[0].geometry.coord.point;
                let path = [];
                points.forEach((point) => {
                  path.push(new BABYLON.Vector3(point.z, point.y, point.x));
                  this.allPathPoints.push(
                    new BABYLON.Vector3(point.z, point.y, point.x)
                  );
                  const distance = BABYLON.Vector3.Distance(
                    new BABYLON.Vector3(point.z, point.y, point.x),
                    lastPoint
                  );
                  this.pathFindingPoints.push({
                    point: new BABYLON.Vector3(point.z, point.y, point.x),
                    distance: distance,
                  });
                  lastPoint = new BABYLON.Vector3(point.z, point.y, point.x);
                });
                //console.log(JSON.stringify(points))
                paths.push(path);
              }
              this.availablePaths = paths;
              for (let path of paths) {
                //this.lines.push(tube2D("line", { path: path, width: 0.05, material: material }, this.scene));
                //console.log("path")
                /*     let mPos = new BABYLON.Vector3(0, 0, 0);
                path.map(a => mPos.addInPlace(a))
                mPos.scaleInPlace(1/path.length)

              //var light = new BABYLON.HemisphericLight("hemiLight", mPos.add(new BABYLON.Vector3(5, 10, 0)), this.scene);
              
              /*
                //console.log(path)
                /*path.forEach(p => {
                  console.log(JSON.stringify(p));
                })
                let ePath = this.extendPath(path);
                console.log("epath")
                ePath.forEach(p => {
                  console.log(JSON.stringify(p));
                })
                */
                //this.mitredExtrude("ribbon", {path: ePath, shape: this.pathShape}, this.scene);
                //line.convertToFlatShadedMesh();
                let extruded = BABYLON.MeshBuilder.ExtrudeShape(
                  "ribbon",
                  {
                    shape: this.pathShape2,
                    path: path,
                    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
                  },
                  this.scene
                );

                //let extruded2 = BABYLON.MeshBuilder.ExtrudeShape("ribbon2", {shape: this.pathShape3, path: path, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, this.scene);
                extruded.material = this.material.transparent;
              }

              // let path = [];
              // let bayblon_path = [];
              // for (let obj of objects) {
              //   const p_obj = obj.children[0];

              //   let points = p_obj.geometry.coord.point;
              //   console.log("insert points: ", points)
              //   points.forEach( (point) =>  {
              //     console.log(point);
              //     const found = _.find(path, {x: point.x, y: point.y, z: point.z});
              //     console.log("Found: ", found)
              //     if (_.isUndefined(found)) {
              //       console.log("Push ", point)
              //       path.push(point);
              //       bayblon_path.push(new BABYLON.Vector3(point.z, point.y , point.x));
              //     } else {
              //       console.log("No Push")
              //     }
              //   })
              // }
              //this.pathdemo1 = points1;
              //console.log(paths);
              //console.log(this.lines);
              // this.line = tube2D("line", { path: bayblon_path, width: 0.02 }, this.scene);

              this.camera.setTarget(
                new BABYLON.Vector3(paths[0][0].x, paths[0][0].y, paths[0][0].z)
              );
            } catch (err) {
              console.log("Error WRL ", err);
            }
            //this.line.material = material;
          });
        }
      }
    },
    calcDirection(myPath) {
      let dir = Array.from({ length: myPath.length - 1 }, (a, idx) => {
        return myPath[idx + 1].subtract(myPath[idx]);
      });
      return dir;
    },
    calcRotationY(myDirection) {
      let axis2 = new BABYLON.Vector3(0, 1, 0);
      let rotBasis = Array.from(myDirection, (a, idx) => {
        return BABYLON.Vector3.RotationFromAxis(
          a.clone(),
          axis2,
          BABYLON.Vector3.Cross(a.clone(), axis2)
        );
      });
      let rot = [rotBasis[0].y + Math.PI / 2];
      // console.log(rot[0])
      let cumRot = rot[0];
      for (let p = 1; p < rotBasis.length; p++) {
        let curRot = rotBasis[p].y - rotBasis[p - 1].y;
        // console.log(curRot);
        if (curRot > Math.PI) {
          curRot -= 2 * Math.PI;
          // let turns = Math.floor(Math.abs(rot[p].y-rot[p-1].y)/(2*Math.PI));
          // console.log("LALA",p, turns);
          // rot[p].y = (rot[p].y - turns*2*Math.PI);// % (2*Math.PI);
        } else if (curRot < -Math.PI) {
          curRot += 2 * Math.PI;
        }
        cumRot += curRot;
        rot.push(cumRot);
      }
      return rot;
    },
    calcRotationRelative(myRotation) {
      let rotRel = Array.from({ length: myRotation.length - 1 }, (a, idx) => {
        return myRotation[idx + 1] - myRotation[idx];
      });
      return rotRel;
    },
    initEventListener() {
      // Watch for browser/canvas resize events
      window.addEventListener("resize", () => {
        this.initCanvasHeight();
        this.engine.resize();
      });

      // Watch Zoom and prevent on window
      window.addEventListener(
        "mousewheel",
        function (e) {
          if (e.deltaY % 1 !== 0) {
            e.preventDefault();
          }
        },
        { passive: false }
      );
    },
    startAnimationInterval: function () {
      this.animationInterval = setInterval(async () => {
        this.setGlow = this.setGlow + this.glowRate * this.glowDirection;
        if (this.setGlow >= 0.9) this.glowDirection = -1;
        if (this.setGlow <= 0.3) this.glowDirection = 1;

        if (!_.isUndefined(this.targetMesh)) {
          this.targetMesh.material.alpha = this.setGlow;
          //this.highlight.addMesh(this.targetMesh, new BABYLON.Color3(0,0,this.setGlow))
        }
      }, this.animationIntervalRate);
    },
    toggleTracker() {
      if (this.trackerButton == "Real") {
        this.trackerButton = "Sim";
      } else {
        this.trackerButton = "Real";
      }
    },
    toggleCam() {
      if (this.activeCamera == 1) {
        this.scene.activeCamera = this.camera2;
        this.camera2.attachControl(this.canvas, true);
        this.light.parent = this.camera2;
        this.activeCamera = 2;
        this.activeCameraLabel = "First Person Cam";
        //console.log(this.animationGroup)
        this.animationGroup.stop();
        this.animationGroup.start();
      } else {
        this.scene.activeCamera = this.camera;
        this.camera.attachControl(this.canvas, true);
        this.light.parent = this.camera;
        this.activeCamera = 1;
        this.activeCameraLabel = "Orbit Cam";
      }
    },

    showWorldAxis(size, offset) {
      /*var makeTextPlane = function(text, color, size) {
        var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", 50, this.scene, true);
        dynamicTexture.hasAlpha = true;
        dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color , "transparent", true);
        var plane = BABYLON.Mesh.CreatePlane("TextPlane", size, this.scene, true);
        plane.material = new BABYLON.StandardMaterial("TextPlaneMaterial", this.scene);
        plane.material.backFaceCulling = false;
        plane.material.specularColor = new BABYLON.Color3(0, 0, 0);
        plane.material.diffuseTexture = dynamicTexture;
        return plane;
      };*/
      var axisX = BABYLON.Mesh.CreateLines(
        "axisX",
        [
          new BABYLON.Vector3(offset.x, offset.y, offset.z),
          new BABYLON.Vector3(offset.x + size, offset.y, offset.z),
        ],
        this.scene
      );
      axisX.color = new BABYLON.Color3(1, 0, 0);
      /*var xChar = makeTextPlane("X", "red", size / 10);
      xChar.position = new BABYLON.Vector3(0.9 * size, -0.05 * size, 0);*/
      var axisY = BABYLON.Mesh.CreateLines(
        "axisY",
        [
          new BABYLON.Vector3(offset.x, offset.y, offset.z),
          new BABYLON.Vector3(offset.x, offset.y + size, offset.z),
        ],
        this.scene
      );
      axisY.color = new BABYLON.Color3(0, 1, 0);
      /*var yChar = makeTextPlane("Y", "green", size / 10);
      yChar.position = new BABYLON.Vector3(0, 0.9 * size, -0.05 * size);*/
      var axisZ = BABYLON.Mesh.CreateLines(
        "axisZ",
        [
          new BABYLON.Vector3(offset.x, offset.y, offset.z),
          new BABYLON.Vector3(offset.x, offset.y, this.offset.z + size),
        ],
        this.scene
      );
      axisZ.color = new BABYLON.Color3(1, 1, 1);
      /*var zChar = makeTextPlane("Z", "blue", size / 10);
      zChar.position = new BABYLON.Vector3(0, 0.05 * size, 0.9 * size);*/
    },
    /* mitredExtrude(name, options, scene) {
      var shape = options.shape;
      var path = options.path;
      var closed = options.close || false;

      var interiorIndex;

      var nbPoints = path.length;
      var line = BABYLON.Vector3.Zero();
      var nextLine = BABYLON.Vector3.Zero();
      var axisX = BABYLON.Vector3.Zero();
      var axisY = BABYLON.Vector3.Zero();
      var axisZ = BABYLON.Vector3.Zero();
      var nextAxisX = BABYLON.Vector3.Zero();
      var nextAxisY = BABYLON.Vector3.Zero();
      var nextAxisZ = BABYLON.Vector3.Zero();
      var startPoint = BABYLON.Vector3.Zero();
      var nextStartPoint = BABYLON.Vector3.Zero();
      var bisector = BABYLON.Vector3.Zero();
      var point = BABYLON.Vector3.Zero();
      var planeParallel = BABYLON.Vector3.Zero();
      var planeNormal = BABYLON.Vector3.Zero();
      var plane = BABYLON.Vector3.Zero();
      var prjctZ = 0;
      var distance = 0;
      var ray;

      var allPaths = [];

      for (var s = 0; s < shape.length; s++) {
        path[1].subtractToRef(path[0], line);
        axisZ = line.clone().normalize();
        axisX = BABYLON.Vector3.Cross(
          scene.activeCamera.position,
          axisZ
        ).normalize();
        axisY = BABYLON.Vector3.Cross(axisZ, axisX);
        startPoint = path[0]
          .add(axisX.scale(shape[s].x))
          .add(axisY.scale(shape[s].y));
        var ribbonPath = [startPoint.clone()];
        for (var p = 0; p < nbPoints - 2; p++) {
          path[p + 2].subtractToRef(path[p + 1], nextLine);
          nextAxisZ = nextLine.clone().normalize();
          nextAxisX = BABYLON.Vector3.Cross(
            scene.activeCamera.position,
            nextAxisZ
          ).normalize();
          nextAxisY = BABYLON.Vector3.Cross(nextAxisZ, nextAxisX);
          nextAxisZ.subtractToRef(axisZ, bisector);
          planeParallel = BABYLON.Vector3.Cross(nextAxisZ, axisZ);
          planeNormal = BABYLON.Vector3.Cross(planeParallel, bisector);
          plane = BABYLON.Plane.FromPositionAndNormal(path[p + 1], planeNormal);
          ray = new BABYLON.Ray(startPoint, axisZ);
          distance = ray.intersectsPlane(plane);
          startPoint.addToRef(axisZ.scale(distance), nextStartPoint);
          ribbonPath.push(nextStartPoint.clone());

          axisX = nextAxisX.clone();
          axisY = nextAxisY.clone();
          axisZ = nextAxisZ.clone();
          startPoint = nextStartPoint.clone();
        }
        // Last Point
        if (closed) {
          path[0].subtractToRef(path[nbPoints - 1], nextLine);
          nextAxisZ = nextLine.clone().normalize();
          nextAxisX = BABYLON.Vector3.Cross(
            scene.activeCamera.position,
            nextAxisZ
          ).normalize();
          nextAxisY = BABYLON.Vector3.Cross(nextAxisZ, nextAxisX);
          nextAxisZ.subtractToRef(axisZ, bisector);
          planeParallel = BABYLON.Vector3.Cross(nextAxisZ, axisZ);
          planeNormal = BABYLON.Vector3.Cross(planeParallel, bisector);
          plane = BABYLON.Plane.FromPositionAndNormal(
            path[nbPoints - 1],
            planeNormal
          );
          ray = new BABYLON.Ray(startPoint, axisZ);
          distance = ray.intersectsPlane(plane);
          startPoint.addToRef(axisZ.scale(distance), nextStartPoint);
          ribbonPath.push(nextStartPoint.clone());

          axisX = nextAxisX.clone();
          axisY = nextAxisY.clone();
          axisZ = nextAxisZ.clone();
          startPoint = nextStartPoint.clone();

          path[1].subtractToRef(path[0], nextLine);
          nextAxisZ = nextLine.clone().normalize();
          nextAxisX = BABYLON.Vector3.Cross(
            scene.activeCamera.position,
            nextAxisZ
          ).normalize();
          nextAxisY = BABYLON.Vector3.Cross(nextAxisZ, nextAxisX);
          nextAxisZ.subtractToRef(axisZ, bisector);
          planeParallel = BABYLON.Vector3.Cross(nextAxisZ, axisZ);
          planeNormal = BABYLON.Vector3.Cross(planeParallel, bisector);
          plane = BABYLON.Plane.FromPositionAndNormal(path[0], planeNormal);
          ray = new BABYLON.Ray(startPoint, axisZ);
          distance = ray.intersectsPlane(plane);
          startPoint.addToRef(axisZ.scale(distance), nextStartPoint);
          ribbonPath.shift();
          ribbonPath.unshift(nextStartPoint.clone());
        } else {
          planeNormal = axisZ;
          plane = BABYLON.Plane.FromPositionAndNormal(
            path[nbPoints - 1],
            planeNormal
          );
          ray = new BABYLON.Ray(startPoint, axisZ);
          distance = ray.intersectsPlane(plane);
          startPoint.addToRef(axisZ.scale(distance), nextStartPoint);
          ribbonPath.push(nextStartPoint.clone());
        }
        allPaths.push(ribbonPath);
      }
      var ribbon = BABYLON.MeshBuilder.CreateRibbon(
        name,
        {
          pathArray: allPaths,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          closeArray: false,
          closePath: closed,
        },
        scene
      );

      return ribbon;
    },*/
    /*extendPath(myPath) {
      var newPath = [myPath[0].clone()];
      for (var p = 1; p < myPath.length; p++) {
        // console.log( Math.abs(-5),myPath[p].y)

        if (Math.abs(myPath[p].y - myPath[p - 1].y) > 0) {
          let oldPoint = myPath[p - 1].clone();
          let newPoint = myPath[p].clone();
          let difVec = newPoint.subtract(oldPoint);
          difVec.y = 0;
          if (difVec.length() > 0) {
            newPath.push(oldPoint.add(difVec.scale(0.05)));
            newPath.push(newPoint.subtract(difVec.scale(0.05)));
          }
          // console.log(p, difVec, difVec.scale(0.001) )
        }
        newPath.push(myPath[p]);
      }
      return newPath;
    },*/

    drawDevices() {
      //let filteredDevices = _.filter(this.Devices, obj => {return obj.coordinates});
      let filteredDevices = _.filter(this.Devices, (obj) => {
        return obj.location;
      });
      //console.log("filteredDevices ", filteredDevices);
      this.loaded_devices = [];
      filteredDevices.forEach(async (device) => {
        if (!device.coordinates) {
          device.coordinates = {
            lat: 47.05156532883682,
            long: 15.434366260645591,
            alt: 0,
          };

          const location = _.find(this.Locations, { _id: device.location });

          for (let mesh of this.Meshes) {
            if (mesh.enabled) {
              //console.log("mesh location ", mesh.location);
              if (
                mesh.location == device.location ||
                mesh.location == location.parent
              ) {
                await this.loadMesh(mesh.file_id);
                mesh.file = this.Mesh;
                //console.log(mesh, this.Mesh)

                const base64File = mesh.file;
                const filenameFile = mesh.name;
                const file = await fromBase64(base64File, filenameFile);
                //let material = this.material[mesh.material];

                const url = window.URL.createObjectURL(file);

                const importedMesh = await this.importMesh(url, filenameFile);
                //console.log("Device ", device)
                //console.log("Imported Mesh ", mesh, importedMesh)
                let coords = importedMesh.getBoundingInfo().boundingBox.center;
                coords.x = coords.x / 1000;
                coords.y = coords.y / 1000;
                coords.z = coords.z / 1000;
                //console.log("coor ", coords)
                let gps = proj4.transform(this.proj4_GIS, this.proj4_GPS, [
                  coords.x,
                  coords.z,
                ]);
                device.coordinates = { lat: gps.y, long: gps.x, alt: coords.y };
                //console.log("GPS ", device.coordinates)
                this.saveDeviceCoordinates({
                  _id: device._id,
                  coordinates: device.coordinates,
                });
              }
            }
          }
        }

        //console.log(device.coordinates.lat, device.coordinates.long);
        let gis = proj4.transform(this.proj4_GPS, this.proj4_GIS, [
          device.coordinates.long,
          device.coordinates.lat,
        ]);
        //let gis = proj4.transform(dest, source, [15.43397860026943,47.05131792631429]);
        /*if (device.coordinates.lat != 47.05156532883682) {
          console.log("Device GIS: ", gis);
        }*/

        let box = BABYLON.MeshBuilder.CreateBox(
          device.device_id,
          {},
          this.scene
        );
        box.position = new BABYLON.Vector3(
          gis.x,
          device.coordinates.alt,
          gis.y
        );
        try {
          switch (device.status.status) {
            case 1:
              box.material = this.material.green;
              break;
            case 2:
              box.material = this.material.yellow;
              break;
            default:
              box.material = this.material.fullRed;
              break;
          }
        } catch (err) {
          box.material = this.material.blue;
        }

        this.loaded_devices.push({
          id: device._id,
          name: device.name,
          mesh: box,
        });
      });
    },
    importMesh(url, filenameFile) {
      return new Promise((resolve) => {
        let f = 1 / this.multi;
        BABYLON.SceneLoader.ImportMesh(
          "",
          "",
          url,
          this.scene,
          (meshes, particleSystems, skeletons) => {
            for (let j = 0; j < meshes.length; ++j) {
              //console.log("Getting Mesh: " + j);
              meshes[j].scaling = new BABYLON.Vector3(f, f, f);
              //meshes[j].material = material;
              meshes[j].flipFaces(false);
              meshes[j].setEnabled(false);
              meshes[j].name = filenameFile;
              //meshes[j].isTarget = isTarget;
              //meshes[j].isPickable = false;
              //this.loadedMeshes2.push(meshes[j]);
            }
            //console.log("IMPORT MESH: ",meshes[0])

            resolve(meshes[0]);
          },
          undefined,
          undefined,
          ".stl"
        );
      });
    },

    stopAnimation() {
      if (this.extrudedPathLine) {
        try {
          //console.log("StopAnimation")
          this.extrudedPathLine.dispose();
          this.animationGroup.stop();
          this.animationNormLine.dispose();
          this.animationBox.dispose();
          this.animationGroup.dispose();
        } catch (err) {
          console.log("StopAnimationErr: ", err);
        }
      }
    },

    generateShortestPath() {
      //FIND START Position
      try {
        //const overlay = document.getElementById('overlay');
        //let pickedMesh = pickResult.pickedMesh;

        this.loaded_meshes.forEach((mesh) => {
          mesh.forEach((subMesh) => {
            if (subMesh.name.includes("huelle")) {
              subMesh.isPickable = true;
            }
          });
        });

        let _points = this.allPathPoints;
        //console.log("Camera: ", this.camera.position);
        //console.log("Camera2: ", this.camera2.position);
        // Nach Z Ebene filtern
        //let filter = _points.filter(p => {return p.y < this.camera2.position.y + 1.5 && p.y > this.camera2.position.y - 1.5});
        let filter = _points;
        //console.log(filter);

        // alle löschen
        for (let point of this.pointsShown) {
          point.dispose();
        }

        // Vector3.Distance Reihung
        let person_location_vector = new BABYLON.Vector3(
          this.person_location.x,
          this.person_location.altitude,
          this.person_location.y
        );
        for (let i = 0; i < filter.length; i++) {
          // let distance = BABYLON.Vector3.Distance(
          //   this.camera.position,
          //   filter[i]
          // );
          // if (this.activeCamera == 2) {
          //  distance = BABYLON.Vector3.Distance(this.camera2.position, filter[i]);
          //}
          //console.log(distance);
          let distance = BABYLON.Vector3.Distance(
            person_location_vector,
            filter[i]
          );
          filter[i].distance = distance;
        }

        let sortFilter = _.sortBy(filter, "distance");
        //console.log(sortFilter);
        //
        //let i = 0;
        /*for (let point of sortFilter) {
              //i = i +0.3;
                //let sphere = BABYLON.Mesh.CreateSphere("Point", 8, 5);
                let sphere = BABYLON.Mesh.CreateBox("Point", 8, this.scene);
                sphere.position = new BABYLON.Vector3(point.x, point.y, point.z);
                this.pointsShown.push(sphere);
              }*/

        //Alle Punkte durchgehen und mit multipickwithray targetieren
        let startPoint = {};

        for (let point of sortFilter) {
          //console.log(point.distance);
          /*let origin = this.camera.position;
          if (this.activeCamera == 2) {
            origin = this.camera2.position;
          }*/

          // Start from Camera2
          //let cam2Position = new BABYLON.Vector3(this.camera2.position.x, this.camera2.position.y, this.camera2.position.z);
          //origin = cam2Position;

          // Start from GeoLocation
          let origin = person_location_vector;

          //console.log(origin);
          let forward = point;

          let direction = forward.subtract(origin);
          direction = BABYLON.Vector3.Normalize(direction);

          var length = 1000;

          var ray = new BABYLON.Ray(origin, direction, length);

          //let rayHelper = new BABYLON.RayHelper(ray);
          //rayHelper.show(this.scene);

          var hit = this.scene.pickWithRay(ray);
          //if (hit.pickedMesh) {console.log("HIT: ", hit.pickedMesh.name);}
          /*if (hit.pickedMesh && hit.pickedMesh.name == "Point") {
                //let sphere = BABYLON.Mesh.CreateSphere("Point", 8, 1);
                //sphere.position = new BABYLON.Vector3(point.x, point.y, point.z);
                this.pointsShown.push(sphere);
                break;
              }*/
          /*if (!hit.pickedMesh) {
                let sphere = BABYLON.Mesh.CreateSphere("Point", 8, 2);
                sphere.position = new BABYLON.Vector3(point.x, point.y, point.z);
                this.pointsShown.push(sphere);
                break;
              }*/

          /*if (hit.pickedMesh && hit.pickedMesh.name == "line") {
                if (startPoint.distance) {
                  if (point.distance < startPoint.distance) {
                    startPoint = point;
                  }
                } else {
                  startPoint = point;
                }
              }*/
          /*if (hit.pickedMesh) {
            console.log("HIT: ", hit.pickedMesh.name);
          }*/
          if (hit.pickedMesh && hit.pickedMesh.name == "ribbon") {
            //if (!startPoint2.distance) {
            startPoint = point;
            //}
            break;
          }
        }
        //console.log("startPoint ",startPoint)
        //console.log("startPoint2 ",startPoint2)
        /* let sphere = BABYLON.Mesh.CreateSphere("Point", 8, 2);
            sphere.position = new BABYLON.Vector3(startPoint.x, startPoint.y, startPoint.z);
            sphere.material = this.material.green;
            this.pointsShown.push(sphere);
  */

        //Find End Position

        let centerTargetMesh =
          this.targetMesh.getBoundingInfo().boundingBox.centerWorld;

        let targetCenter = new BABYLON.Vector3(
          centerTargetMesh.x, // / 1000,
          centerTargetMesh.y, // / 1000,
          centerTargetMesh.z // / 1000
        );
        //console.log("targetMesh ", this.targetMesh.getBoundingInfo())
        //console.log("targetCenter ", targetCenter.x, targetCenter.y, targetCenter.z)
        //   sphere = BABYLON.Mesh.CreateSphere("centerTargetMesh", 8, 5);
        /*sphere.position = new BABYLON.Vector3(centerTargetMesh.x , centerTargetMesh.y, centerTargetMesh.z);
            this.pointsShown.push(sphere);*/
        // Vector3.Distance Reihung

        // Vector3.Distance Reihung

        // Nach Z Ebene filtern
        filter = _points.filter((p) => {
          return p.y < targetCenter.y + 2.5 && p.y > targetCenter.y - 2.5;
        });
        //console.log(filter)
        for (let i = 0; i < filter.length; i++) {
          const distance = BABYLON.Vector3.Distance(targetCenter, filter[i]);
          //console.log(distance, filter[i].x, filter[i].y, filter[i].z);
          filter[i].distance = distance;
        }
        sortFilter = _.sortBy(filter, "distance");

        // if (sortFilter[0].distance) {
        //     let sphere = BABYLON.Mesh.CreateSphere("Point", 8, 2);
        //     sphere.position = new BABYLON.Vector3(sortFilter[0].x, sortFilter[0].y, sortFilter[0].z);
        //     sphere.material = this.material.red;
        // this.pointsShown.push(sphere);
        // }
        const endPoint = sortFilter[0];
        console.log("endPoint", endPoint);
        // PATH Finding
        const myGraph = new graph();

        // available Paths: Punkte der einzelnen Pfade verbinden
        let idxPoints = [];
        let idx = 0;
        for (let i = 0; i < this.availablePaths.length; i++) {
          for (let p = 0; p < this.availablePaths[i].length - 1; p++) {
            idxPoints.push({ idx: idx, point: this.availablePaths[i][p] });
            const distance = BABYLON.Vector3.Distance(
              this.availablePaths[i][p],
              this.availablePaths[i][p + 1]
            );
            //console.log(idx, i, p, p+1, this.availablePaths[i][p].x, distance);
            myGraph.addEdge(idx, idx + 1, distance);
            //console.log(idx, idx+1, distance);
            /*let searchPointArr1 = [this.availablePaths[i][p].x, this.availablePaths[i][p].y, this.availablePaths[i][p].z];
                let searchPointArr2 = [this.availablePaths[i][p+1].x, this.availablePaths[i][p+1].y, this.availablePaths[i][p+1].z];
                if (_.isEqual(startPointArr, searchPointArr1) || _.isEqual(startPointArr, searchPointArr2) ) {

                    startIdx = idx;
                  } else if (_.isEqual(endPointArr,searchPointArr1) || _.isEqual(endPointArr, searchPointArr2)){
                    endIdx = idx;
                  }*/
            idx++;
          }
          idxPoints.push({
            idx: idx,
            point: this.availablePaths[i][this.availablePaths[i].length - 1],
          });
          idx++; // da p -1
          idx++;
        }

        //console.log(idxPoints);

        //console.log("Start Point ", startPoint);
        //console.log("End Point ", endPoint);

        let startPointArr = [
          startPoint.x.toFixed(2),
          startPoint.y.toFixed(2),
          startPoint.z.toFixed(2),
        ];
        let endPointArr = [
          endPoint.x.toFixed(2),
          endPoint.y.toFixed(2),
          endPoint.z.toFixed(2),
        ];

        let startIdx;
        let endIdx;

        for (let i = 0; i < idxPoints.length; i++) {
          let searchPointArr = [
            idxPoints[i].point.x.toFixed(2),
            idxPoints[i].point.y.toFixed(2),
            idxPoints[i].point.z.toFixed(2),
          ];
          if (_.isEqual(startPointArr, searchPointArr)) {
            startIdx = idxPoints[i].idx;
          }
          if (_.isEqual(endPointArr, searchPointArr)) {
            endIdx = idxPoints[i].idx;
          }
        }

        // suchen nach Überschneidungen, diese Punkte mit distance 0 verbinden

        const checkIfSame = (check, i, p) => {
          //console.log("Check: ", check, i, p, this.availablePaths);
          let searchPointArr1 = [check.x, check.y, check.z];
          let idx2 = 0;
          for (let i2 = 0; i2 < this.availablePaths.length; i2++) {
            for (let p2 = 0; p2 < this.availablePaths[i2].length; p2++) {
              let searchPointArr2 = [
                this.availablePaths[i2][p2].x,
                this.availablePaths[i2][p2].y,
                this.availablePaths[i2][p2].z,
              ];
              if (_.isEqual(searchPointArr1, searchPointArr2)) {
                if (i != i2 || p != p2) {
                  return { i2: i2, p2: p2, idx2: idx2 };
                }
              }
              idx2++;
            }
            idx2++;
          }

          return undefined;
        };

        idx = 0;
        //let overlapArray = [];
        for (let i = 0; i < this.availablePaths.length; i++) {
          for (let p = 0; p < this.availablePaths[i].length; p++) {
            const check = checkIfSame(this.availablePaths[i][p], i, p);
            if (check) {
              //console.log("Overlap found ", i, p, check);
              //overlapArray.push([i,p,check.i2,check.p2,idx, check.idx2]);
              //const distance = BABYLON.Vector3.Distance(this.availablePaths[i][p],this.availablePaths[i2][p2]);
              myGraph.addEdge(idx, check.idx2, 0);
              //console.log(idx, check.idx2, 0);
            }
            idx++;
          }
          idx++;
        }

        // for (let lo = 0; lo < overlapArray.length; lo++) {
        //   const distance = BABYLON.Vector3.Distance(this.availablePaths[overlapArray[lo][0]][overlapArray[lo][1]],this.availablePaths[overlapArray[lo][2]][overlapArray[lo][3]]);
        //   myGraph.addEdge(overlapArray[lo][4], overlapArray[lo][5], distance);
        //   console.log(overlapArray[lo][4], overlapArray[lo][5], distance);
        // }

        //ToDo: gespiegelte Dubletten aus overlapArray entfernen

        /*  for (let i = 0; i< this.pathFindingPoints.length; i++) {
              //let n = Number(i)+1;
              
              let n = i+1;
              if (this.pathFindingPoints[n]) {
                myGraph.addEdge(i, n, this.pathFindingPoints[n].distance);
                //console.log("addEdge: ", i, n, this.pathFindingPoints[n].distance);
              }
              if (startPoint.x == this.pathFindingPoints[i].point.x && startPoint.y == this.pathFindingPoints[i].point.y && startPoint.z == this.pathFindingPoints[i].point.z ) {
                startIdx = i;
              } else if (endPoint.x == this.pathFindingPoints[i].point.x && endPoint.y == this.pathFindingPoints[i].point.y && endPoint.z == this.pathFindingPoints[i].point.z ){
                endIdx = i;
              }
            }*/

        //console.log("Start Point ", startPoint.x, startPoint.y, startPoint.z, startIdx);
        //console.log("End Point ", endPoint.x, endPoint.y, endPoint.z, endIdx);

        const shortestPath = myGraph.shortestPath(startIdx, endIdx);
        const [path, distance] = shortestPath;

        //console.log(`Path ${path}`);
        //console.log(`Distance ${distance}`);
        //console.log(path);

        for (let line of this.linesShortestPath) {
          line.dispose();
        }
        this.linesShortestPath = [];

        let shortestPathArray = [];
        for (let i = 0; i < path.length; i++) {
          const point = _.find(idxPoints, { idx: path[i] });
          shortestPathArray.push(point.point);
        }

        //Add PathPoint from Cam2 to Path
        //let cam2Position = new BABYLON.Vector3(this.camera2.position.x, this.camera2.position.y, this.camera2.position.z);
        //shortestPathArray.unshift(cam2Position);
        //Add PathPoint from PersonGeoLocation to Path

        shortestPathArray.unshift(person_location_vector);

        //console.log(shortestPathArray);

        //this.linesShortestPath.push(tube2D("line", { path: shortestPathArray, width: 0.3, material: this.material.red }, this.scene));

        /*      let eShortestPathArray = this.extendPath(shortestPathArray);

        this.extrudedPathLine = BABYLON.MeshBuilder.ExtrudeShape(
          "line",
          {
            shape: this.pathShape2,
            path: eShortestPathArray,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          },
          this.scene
        );
        this.extrudedPathLine.material = this.material.fullRed;
  */

        let mPos = new BABYLON.Vector3(0, 0, 0);

        shortestPathArray.map((a) => mPos.addInPlace(a));
        mPos.scaleInPlace(1 / shortestPathArray.length);

        let fps = 30;
        let mps = 3; //meters per second
        let movSpd = fps / mps; //meter per second => frames per meter
        let rps = 0.5; //rounds per second
        let rotSpd = fps / (Math.PI * 2 * rps); //frames per rotation

        let myP = Array.from(shortestPathArray);
        let myDir = this.calcDirection(myP);
        let myRot = this.calcRotationY(myDir);
        let myRotRel = this.calcRotationRelative(myRot);
        let movFrames = myDir.map((a) => Math.ceil(a.length() * movSpd));
        //console.log("myDir",myDir)
        //console.log("movFrames",movFrames);
        let rotFrames = myRotRel.map((a) => Math.ceil(Math.abs(a) * rotSpd));

        /*    var path3d = new BABYLON.Path3D(shortestPathArray);

        // Define the position and orientation animations that will be populated
        // according to the Path3D properties
        var curvePath = path3d.getCurve();
        var tangents = path3d.getTangents();
        var normals = path3d.getNormals();
        var binormals = path3d.getBinormals();
  */
        //this.linesShortestPath.push(tube2D("line", { path: curvePath, width: 0.5, material: this.material.blue }, this.scene));

        let camOffset = new BABYLON.Vector3(0, 1.7, 0);

        //create keys:
        let myCam = Array.from(myP, (a) => {
          let b = a.clone();
          b.addInPlace(camOffset);
          return b;
        });
        //create keys:
        let movKeys = [
          {
            frame: 0,
            value: myCam[0],
          },
        ];
        let rotKeys = [
          {
            frame: 0,
            value: myRot[0],
          },
        ];
        let cumFrames = 0;
        for (let p = 1; p < myP.length - 1; p++) {
          cumFrames += movFrames[p - 1];
          movKeys.push({
            frame: cumFrames,
            value: myCam[p],
          });
          rotKeys.push({
            frame: cumFrames,
            value: myRot[p - 1],
          });
          cumFrames += rotFrames[p - 1];
          movKeys.push({
            frame: cumFrames,
            value: myCam[p],
          });
          rotKeys.push({
            frame: cumFrames,
            value: myRot[p],
          });
        }
        let p = myP.length - 1;
        cumFrames += movFrames[p - 1];
        movKeys.push({
          frame: cumFrames,
          value: myCam[p],
        });
        rotKeys.push({
          frame: cumFrames,
          value: myRot[p - 1],
        });

        // console.log("cumFrames", cumFrames);
        //console.log("movKeys",movKeys);
        //console.log("rotKeys",rotKeys);

        this.extrudedPathLine = BABYLON.MeshBuilder.CreateTube(
          "extrudedPathLine",
          {
            path: myP,
            tessellation: 2,
            radius: 0.3,
          },
          this.scene
        );
        this.extrudedPathLine.convertToFlatShadedMesh();
        this.extrudedPathLine.material = this.material.fullRed;

        //const frameRate = 60;
        const posAnim = new BABYLON.Animation(
          "cameraPos",
          "position",
          fps,
          BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
          BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
        );
        //const posKeys = [];
        const rotAnim = new BABYLON.Animation(
          "cameraRot",
          "rotation.y",
          fps,
          BABYLON.Animation.ANIMATIONTYPE_FLOAT,
          BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
        );
        //const rotAnim = new BABYLON.Animation("cameraRot", "rotation", frameRate, BABYLON.Animation.ANIMATIONTYPE_QUARTERNION, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
        /*const rotKeys = [];

        //let NullVector = new BABYLON.Vector3(0,0,0);
        //let RotVector = new BABYLON.Vector3(0,0,0);
        //let rot1 = 0;

        for (let i = 0; i < curvePath.length; i++) {
          let position = curvePath[i];

          position.y = position.y + 1.7;

          //let pos2 = new BABYLON.Vector3(position.x, position.y + 20, position.z)

          const normal = normals[i];
          const binormal = binormals[i];
          const tangent = tangents[i];
          // console.log(normal, binormal, tangent);

          //  RotVector.x  = rot1;
          //     RotVector.y  = i/50;
          //     RotVector.z  = i/20;
          //     rot1 = rot1 + 0.1;
          //     const rotV = _.cloneDeep(RotVector);
          //const rotation = BABYLON.Vector3.RotationFromAxis(rotV, NullVector, NullVector);
          //const NullVector = new BABYLON.Vector3(0,0,0);
          // const rotation = BABYLON.Vector3.RotationFromAxis(NullVector, NullVector, normal);
          const rotation = BABYLON.Vector3.RotationFromAxis(
            normal,
            binormal,
            tangent
          );
          // const rotation = BABYLON.Quaternion.RotationQuaternionFromAxis(normal, binormal, tangent);

          //console.log(rot1, i, RotVector, rotV,rotation);
          posKeys.push({ frame: i * frameRate, value: position });
          rotKeys.push({ frame: i * frameRate, value: rotation });
        }*/

        posAnim.setKeys(movKeys);
        rotAnim.setKeys(rotKeys);

        //this.camera2.animations.push(posAnim);
        // this.camera2.animations.push(rotAnim);
        //this.camera2.position = startPoint;
        //this.camera2.setTarget = endPoint;

        /*       if (this.activeCamera == 1) {
                    this.toggleCam();
                  }
        */
        //this.scene.beginAnimation(this.camera2, 0, frameRate*curvePath.length, true);
        //this.scene.stopAnimation(this.camera2);

        //Create and draw a plane in xy plane to trace the curve at (0, 0, 0)

        this.animationBox = BABYLON.MeshBuilder.CreateBox(
          "animationBox",
          {},
          this.scene
        );
        const norm = new BABYLON.Vector3(0, 0, 1); //normal to plane
        const pos_of_norm = new BABYLON.Vector3(0, 0, 0); // position of normal (for display)

        //Draw the normal line in red
        this.animationNormLine = BABYLON.Mesh.CreateLines(
          "normLine",
          [pos_of_norm, pos_of_norm.add(norm).scale(2)],
          this.scene
        );
        this.animationNormLine.color = BABYLON.Color3.Red();

        //Set box as parent of normal line so they move and turn as one
        this.animationNormLine.parent = this.animationBox;
        // Create the animation group

        // this.animationGroup = new BABYLON.AnimationGroup("Group");
        this.animationGroup.addTargetedAnimation(posAnim, this.camera2);
        this.animationGroup.addTargetedAnimation(rotAnim, this.camera2);

        this.animationGroup.addTargetedAnimation(posAnim, this.animationBox);
        this.animationGroup.addTargetedAnimation(rotAnim, this.animationBox);

        this.animationGroup.play(true);

        /*
    let cone = BABYLON.MeshBuilder.CreateCylinder(
          "cone", 
          {
              height: 0.7,
              diameterTop: 0,
              diameterBottom: 0.5,
              tessellation: 3
          },
          this.scene
      );
      cone.convertToFlatShadedMesh();
  let animConeMov = new BABYLON.Animation(
          "ConeMov", 
          "position", 
          fps, 
          BABYLON.Animation.ANIMATIONTYPE_VECTOR3, 
          BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
      );
      let animConeRot = new BABYLON.Animation(
          "ConeRot", 
          "rotation.y", 
          fps, 
          BABYLON.Animation.ANIMATIONTYPE_FLOAT, 
          BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
      );
      animConeMov.setKeys(movKeys);
      animConeRot.setKeys(rotKeys);

      cone.animations = [];
      cone.animations.push(animConeMov);
      cone.animations.push(animConeRot);
      this.scene.beginAnimation(cone, 0, cumFrames, true);
  */
        this.loaded_meshes.forEach((mesh) => {
          mesh.forEach((subMesh) => {
            subMesh.isPickable = false;
          });
        });
      } catch (err) {
        console.log("shorted Path Error: ", err);
        this.loaded_meshes.forEach((mesh) => {
          mesh.forEach((subMesh) => {
            subMesh.isPickable = false;
          });
        });
      }
    },

    async saveDevice() {
      //console.log("SaveDevice ", this.pickedMesh.name)
      let coords = this.pickedMesh.position;
      let gps = proj4.transform(this.proj4_GIS, this.proj4_GPS, [
        coords.x,
        coords.z,
      ]);
      let pickedDevice = _.find(this.Devices, {
        device_id: this.pickedMesh.name,
      });
      await this.saveDeviceCoordinates({
        _id: pickedDevice._id,
        coordinates: { lat: gps.y, long: gps.x, alt: coords.y },
      });
    },
    setPosSteps() {
      if (this.positioningSteps == 0.5) {
        this.positioningSteps = 5;
      } else {
        this.positioningSteps = 0.5;
      }
    },

    getGeoLocation() {
      this.isGettingGeoLocation = true;
      geolocator.config({
        language: "de",
        google: {
          version: "3",
          key: "YOUR-GOOGLE-API-KEY",
        },
      });
      geolocator.locate(this.geoLocatorOptions, (err, location) => {
        if (err) {
          this.isGettingGeoLocation = false;
          //console.log(err);
          //return console.log(err);
          return err;
        }
        if (location.coords) {
          let gis = proj4.transform(this.proj4_GPS, this.proj4_GIS, [
            location.coords.longitude,
            location.coords.latitude,
          ]);
          //console.log("GEOLOC: ", location, gis);
          if (location.coords.altitude == null) {
            gis.altitude = 1;
          } else {
            gis.altitude = location.coords.altitude - this.altitudeCorrFactor;
          }
          this.person_location = gis;
        }
        this.isGettingGeoLocation = false;
      });
    },

    setPickedMeshCoordsManual() {
      //console.log(this.pickedMeshCoordsManual)
      const manualCoords = JSON.parse(this.pickedMeshCoordsManual);
      this.pickedMesh.position.x = manualCoords.x;
      this.pickedMesh.position.y = manualCoords.y;
      this.pickedMesh.position.z = manualCoords.z;
    },
  },
  watch: {
    tracking(now, last) {
      if (now) {
        this.trackingInterval = setInterval(() => {
          if (!this.isGettingGeoLocation) {
            //console.log("Getting GeoLocation Interval")

            if (this.trackerButton == "Sim") {
              this.person_location = {
                x: -68220,
                y: 212893.072,
                altitude: 1,
              };
            } else {
              this.getGeoLocation();
            }
          }
        }, 1000);
      } else {
        this.IntervalOff();
      }
    },

    async location(now, last) {
      if (!_.isUndefined(now)) {
        this.stopAnimation();
        //console.log("Watch Location " + now + " " + last);
        this.privat = undefined;
        this.company = undefined;
        this.selected_device = undefined;
        this.location_id = this.Locations[now]._id;
        this.description = "";
        this.locationInfo = "keine";
        await this.getEnabledMeshes();
        this.renderScene();
      }
    },
    async company(now, last) {
      if (!_.isUndefined(now)) {
        this.stopAnimation();
        //console.log("Watch Company " + now + " " + last);
        this.privat = undefined;
        this.location = undefined;
        this.selected_device = undefined;
        this.location_id = this.Company[now].location;
        this.description = this.Company[now].description;
        this.locationInfo = this.Company[now].locationInfo;
        // console.log(this.location_id, this.locationInfo)
        await this.getEnabledMeshes();
        this.renderScene();
      }
    },
    async privat(now, last) {
      if (!_.isUndefined(now)) {
        this.stopAnimation();
        // console.log("Watch Privat " + now + " " + last);
        this.location = undefined;
        this.company = undefined;
        this.selected_device = undefined;
        this.location_id = this.Privat[now].location;
        this.description = this.Privat[now].description;
        this.locationInfo = this.Privat[now].locationInfo;
        await this.getEnabledMeshes();
        this.renderScene();
      }
    },
    async selected_device(now, last) {
      if (!_.isUndefined(now)) {
        this.stopAnimation();
        // console.log("Watch Device " + now + " " + last);
        this.location = undefined;
        this.company = undefined;
        this.privat = undefined;
        const device = _.find(this.Devices, { _id: now });
        this.location_id = device.location;
        this.description = JSON.stringify(device.status);
        this.locationInfo = device.description;

        await this.getEnabledMeshes();
        this.renderScene();
        const loaded_device = _.find(this.loaded_devices, { id: now });
        this.pickedMesh = loaded_device.mesh;
        let coords = {};
        coords.x = loaded_device.mesh.position.x;
        coords.y = loaded_device.mesh.position.y;
        coords.z = loaded_device.mesh.position.z;
        this.pickedMeshCoordsManual = JSON.stringify(coords);

        // console.log("pickedMesh ", loaded_device.mesh);

        this.targetMesh = loaded_device.mesh;
        this.generateShortestPath();
      }
    },

    selectedAllItem(now, last) {
      console.log("selectedAllItem");
      this.pickedMesh = undefined;
      if (_.isUndefined(now)) {
        this.selectedAllItem = last;
        now = last;
      }

      if (!_.isUndefined(now)) {
        const item = this.filteredAllItems[now];

        if (item) {
          //console.log(item)
          let find = _.find(this.personsCompany, (obj) => {
            return obj._id == item._id;
          });
          if (find) {
            this.company = find.index;
          } else {
            find = _.find(this.personsPrivat, (obj) => {
              return obj._id == item._id;
            });
            if (find) {
              this.privat = find.index;
            } else {
              find = _.find(this.locationNames, (obj) => {
                return obj._id == item._id;
              });
              if (find) {
                this.location = find.index;
              } else {
                find = _.find(this.Devices, (obj) => {
                  return obj._id == item._id;
                });
                //console.log("Device: ", find)
                if (find) {
                  this.selected_device = find._id;
                }
              }
            }
          }
        }
        //console.log(find);
      }
      //console.log("SimpleKeyboard", SimpleKeyboard);
      this.keyboard.clearInput();
      this.input = "";
    },
    /* async location_id() {
      await this.getEnabledMeshes();
      this.renderScene();
    },*/

    person_location(now, last) {
      if (!_.isUndefined(this.person_mesh)) {
        this.person_mesh.dispose();
      }
      this.person_mesh = BABYLON.MeshBuilder.CreateCylinder(
        "person",
        {
          height: 2.5,
          diameterTop: 1.5,
          diameterBottom: 0,
          tessellation: 10,
        },
        this.scene
      );
      this.person_mesh.convertToFlatShadedMesh();
      this.person_mesh.material = this.material.yellow;

      this.person_mesh.position = new BABYLON.Vector3(
        this.person_location.x,
        this.person_location.altitude,
        this.person_location.y
      );
      this.person_mesh.isPickable = false;
      this.camera2.position = this.person_mesh.position;
    },
  },
};
</script>

<style lang='scss'>
.card--flex-toolbar {
  margin-top: 0px;
  margin-bottom: 0px;
}
// .home {
//   overflow: hidden;
// }

/*
  Theme: myTheme1
*/

.simple-keyboard .hg-button {
  height: 35px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(50, 50, 50, 0.5);
  color: white;
}

.simple-keyboard .hg-button:active {
  background: rgba(255, 255, 255, 0.5);
  color: rgba(50, 50, 50, 0.5);
}

.simple-keyboard {
  max-width: 500px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.simple-keyboard .hg-button.hg-functionBtn.hg-button-space {
  min-width: 150px;
  max-width: none;
}

.simple-keyboard.hg-theme-default .hg-button[data-skbtnuid^="numbers-"] {
  width: 33%;
  max-width: none;
}

.simple-keyboard .hg-button-numbers {
  max-width: 80px;
}

.simple-keyboard .hg-button.hg-functionBtn.hg-button-ent {
  max-width: 80px;
}

.drawerbutton {
  position: fixed;
  top: 0px;
  right: 0px;
}

.logo {
  position: fixed;
  bottom: 10px;
  left: 10px;
  //opacity: 99%;
}

.logo2 {
  position: fixed;
  top: 10px;
  left: 10px;
  //opacity: 99%;
}

.bottom_info {
  position: fixed;
  top: var(--canvas_height);
  left: 10px;
  font-size: var(--canvas_font_size); 
}


.whitebox {
  border: 1px solid white; 
  border-radius: 5px; 
  height: 37px; 
}


.v-textarea textarea {
  line-height: 1.5rem !important;
}

.logo {
  position: fixed;
  z-index: 9999;
  width: 300px;
  top: 50%;
  margin-top: -150px;
  left: 50%;
  margin-left: -150px;
}

.logo-console{
  position: fixed;
  z-index: 9999;
  bottom: 1%;
  left: 1%;
}

.logo-overlay {
  position: fixed;
  z-index: 9998;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: black;
}

</style>