<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex style="max-width:500px">
        <v-card flat color="transparent">
          <v-card-text>
            <template v-if="step === 'resetPasswordConfirm'">
              <v-card dark>
                <v-card-title class="title">{{$t("Reset password")}}</v-card-title>

                <template v-if="statusResetPassword === 'failure'">
                  <v-card-text>
                    <v-alert type="error" value="true">Password reset error</v-alert>
                  </v-card-text>
                  <v-card-text class="text-xs-center">Link is invalid.</v-card-text>
                </template>

                <template v-if="statusResetPassword === 'success'">
                  <v-card-text>
                    <v-alert type="info" value="true">Password reset success</v-alert>
                  </v-card-text>
                  <v-card-text
                    class="text-xs-center"
                  >New temporary password was sent to your e-mail address.</v-card-text>

                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn rounded color="primary" :to="{name: 'login'}">Log In</v-btn>
                  </v-card-actions>
                </template>
              </v-card>
            </template>

            <template v-else>
              <v-card dark>
                <v-card-title class="title">Reset password</v-card-title>

                <template v-if="statusForgotPassword === 'success'">
                  <v-card-text>
                    <v-alert
                      type="info"
                      value="true"
                    >Your request for password reset successfully submitted.</v-alert>
                  </v-card-text>
                  <v-card-text class="text-xs-center">Please check your e-mail.</v-card-text>
                </template>

                <template v-else>
                  <v-card-text>
                    <v-alert
                      type="error"
                      :value="statusForgotPassword === 'failure'"
                    >Something went wrong</v-alert>
                  </v-card-text>
                  <v-card-text>
                    <v-form v-model="valid" style="width:100%;height:100%;">
                      <v-text-field :rules="emailRule" required v-model="email" label="E-Mail"></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      rounded
                      :disabled="!valid"
                      color="primary"
                      @click="forgotPassword({email})"
                    >{{$t("Reset password")}}</v-btn>
                  </v-card-actions>
                </template>
                <v-divider></v-divider>
                <p align="center">
                  <br />
                  <router-link :to="{name: 'login'}">{{$t("Back to Login")}}</router-link>
                  <br />
                  <br />
                </p>
              </v-card>
            </template>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { emailRule } from "../utils/rules";

export default {
  data() {
    return {
      email: "",
      valid: false,
      emailRule
    };
  },
  methods: {
    ...mapActions("user", ["forgotPassword", "resetPassword"])
  },
  computed: {
    ...mapState({
      statusForgotPassword: ({ user }) => user.statusForgotPassword,
      statusResetPassword: ({ user }) => user.statusResetPassword
    }),
    step: function() {
      switch (this.$route.name) {
        case "resetPassword":
        case "resetPasswordConfirm":
          return this.$route.name;
      }
      return null;
    }
  },
  mounted() {
    const { token } = this.$route.params;
    if (this.step === "resetPasswordConfirm") {
      this.resetPassword({ token });
    }
  },
  watch: {
    "$route.params.token"(val) {
      this.resetPassword({ token: val });
    }
  }
};
</script>

<style scoped>
</style>
