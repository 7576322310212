<template>

  <div ref='canvas'></div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
//import GLTFExporter from 'three-gltf-exporter';

// import { mapState, mapActions } from "vuex";
import _ from "lodash";
// import api from "../api";

// import { fromBase64, toBase64 } from "../functions/base64_helpers";

//   // import fs from 'fs'
// import { join } from 'path'
// import Helper from "dxf"

export default {
  name: "three",
  components: {},
  data() {
    const scene = new THREE.Scene()
        // const composer = new THREE.EffectComposer(new WebGLRenderer())
        // const effectPass = new THREE.EffectPass(camera, new BloomEffect())
        const camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        )
        const renderer = new THREE.WebGLRenderer({ antialias: true })
        const light = new THREE.DirectionalLight('hsl(0, 100%, 100%)')
        const geometry = new THREE.BoxGeometry(1, 1, 1)
        const material = new THREE.MeshStandardMaterial({
            side: THREE.FrontSide,
            color: 'hsl(0, 100%, 50%)',
            wireframe: false
        })
        const cube = new THREE.Mesh(geometry, material)
        const axes = new THREE.AxesHelper(5)

        return {
            scene: scene,
            camera: camera,
            controls: [],
            renderer: renderer,
            light: light,
            cube: cube,
            axes: axes,
            speed: 0.01
        }
  },
  created: function() {
        this.scene.add(this.camera)
        this.scene.add(this.light)
        // this.scene.add(this.cube)
        this.scene.add(this.axes)
        this.renderer.setSize(window.innerWidth, window.innerHeight)
        this.light.position.set(0, 0, 10)
        this.camera.position.z = 5
        this.scene.background = new THREE.Color('hsl(0, 100%, 100%)')
        
    },
  async mounted() {
    // // const helper = new Helper(fs.readFileSync('../assets/Floor_Plan_Sample.json', 'utf-8'));
    // const scene = new THREE.Scene();
    // const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
    // scene.add(camera)
    // const renderer = new THREE.WebGLRenderer();
    // renderer.setSize( 640, 320 );

    // console.log(this.$refs.canvas)
    // this.$refs.canvas.appendChild(renderer.domElement)

    const loader = new THREE.ObjectLoader();
    // let object = await loader.loadAsync('Floor_Plan_Sample.json');
    // let object = await loader.loadAsync('floor2.json');
   
    // console.log(object)
    // this.scene.add(object);

    const gltfloader = new GLTFLoader();
    const gltfobject = await gltfloader.loadAsync('scene.gltf');
    let object = new THREE.Object3D();
    object = gltfobject.scene;
    object.scale.x = 0.01;
    object.scale.y = 0.01;
    object.scale.z = 0.01;
    this.scene.add(object);

    // const exporter = new GLTFExporter();
    // exporter.parse(object, 
    //   async function (gltf) { 
        
    //     const blob = new Blob([gltf]);
    //     const url = window.URL.createObjectURL(blob);
    //     console.log(gltf, blob);
    //     
    //     // const object_gltf = await gltfloader.loadAsync(url);
    //     // this.scene.add(object_gltf);
    //     gltfloader.load(url , (file)=>{
    //         console.log(file);
    //     });
    //   }, 
    //   function (error) {
    //     console.log(error);
    //   }
    // );

  

   

    // const geometry = new THREE.BoxGeometry( 1, 1, 1 );
    // const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    // const cube = new THREE.Mesh( geometry, material );
    // scene.add( cube );

    // camera.position.z = 5;

    this.$refs.canvas.appendChild(this.renderer.domElement)
    // this.animate()
    this.renderer.render(this.scene, this.camera)
    
  },
  computed: {
    rotate: function() {
          if (this.speed === '') {
              return 0
          } else {
              return this.speed
          }
      }

  },
  methods: {
   animate: function() {
            requestAnimationFrame(this.animate)
            this.renderer.render(this.scene, this.camera)
            this.cube.rotation.y += this.speed
           
        }
  },
  watch: {

  },
};
</script>