
<template>
  <div id="app">
    <!-- <ARScene /> -->
    <v-app>
      <v-navigation-drawer
        color="black"
        v-model="Drawer"
        app
        clipped
        overlay-color="red"
        overlay-opacity="50%"
        :style="`margin-top: ${$vuetify.application.top}px`"
        temporary
        width="200"
        v-if="mySelf"
      >
        <v-list class="pt-3">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            v-on:click="
              $store.state.drawer = false;
              pushRoute(item);
            "
            class="pt-2"
          >
            <v-list-item-action>
              <v-icon medium :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize text-white">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pt-2" v-if="mySelf" v-on:click="logout()">
            <v-list-item-action>
              <v-icon medium color="white">logout</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize text-white">{{
                $t("Logout")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-card v-if="mySelf" color="black">
        <!-- <v-app-bar clipped-left fixed app color="primary">
          <v-col cols="auto" align="left" class="ma-0 pa-0">
            <v-btn class="drawerbutton" large icon v-on:click="drawer = !drawer">
              <v-icon color="white">menu</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="9" align="left">
            <span class="mt-2 white--text caption text-sm-h6"
              >Tagger Visualize App
            </span>
            <span class="mt-2 white--text caption text-sm-h6">
              V {{ version }}</span
            >
          </v-col>

          <v-col align="right" class="caption pa-0 ma-0">
            <span class="mt-2 white--text caption text-sm-subtitle"
              >Schwarzl IT 2021</span
            >
          </v-col>
        </v-app-bar> -->

        <v-main>
          <router-view></router-view>
        </v-main>
      </v-card>
      <v-card v-if="!mySelf" color="black">
        <v-row row pb-2 pt-3>
          <v-col xs12 pl-1 ml-1 pr-1 mr-1>
            <v-card class="card--flex-toolbar">
              <!-- <v-app-bar flat color="primary">
                <v-spacer></v-spacer>

                <v-toolbar-title class="white--text text-capitalize h3"
                  >Tagger Visualize App</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <p class="caption" align="right">Schwarzl IT 2020</p>
              </v-app-bar> -->
              <v-progress-linear
                color="blue"
                :active="loading"
                :height="5"
                class="ma-0"
                indeterminate
              ></v-progress-linear>
              <v-img class="white--text align-start" width="100%" src="">
                <router-view></router-view>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";

// import ARScene from "./views/ARScene2";
//import ARScene from "./views/ARScene";
export default {
  name: "App",
  components: {
    // ARScene,
  },
  data() {
    return {
      drawer: false,
      items: [],
      //mini: true,
      right: null,
      theme1: undefined,
      version: "0.9.1",
    };
  },
  async mounted() {
    this.theme1 = JSON.stringify(this.$vuetify.theme.themes);
    //await this.getSmApi({});
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      // Drawer: {
      //   get() {
      //     return this.$store.state.drawer;
      //   },
      //   set(newValue) {
      //     return this.$store.dispatch("setDrawer", newValue);
      //   },
      // },
    }),
  },
  watch: {
    mySelf() {
      this.items = [];
      try {
        // if (this.mySelf.permissions.includes("user")) {
        // }
        // else if (this.mySelf.roles.includes("admin")) {
        this.$router.options.routes.forEach((route) => {
          if (!_.isUndefined(route.icon)) {
            if (!(route.name === "admin") && !(route.name === "location")) {
              this.items.push({
                title: route.name,
                icon: route.icon,
                color: route.color,
                path: route.path,
              });
            }
          }
        });
        // if (!_.find(this.items, { title: "admin" })) {
        //   const route = _.find(this.$router.options.routes, {
        //     name: "admin"
        //   });
        //   this.items.push({
        //     title: route.name,
        //     icon: route.icon,
        //     color: route.color,
        //     path: route.path
        //   });
        // }
        // if (!_.find(this.items, { title: "location" })) {
        //   const route = _.find(this.$router.options.routes, {
        //     name: "location"
        //   });
        //   this.items.push({
        //     title: route.name,
        //     icon: route.icon,
        //     color: route.color,
        //     path: route.path
        //   });
        // }
        // }
      } catch {}
    },
  },
  methods: {
    ...mapActions("auth", ["getMySelf", "logout"]),
    /*    ...mapActions(
      "sm_api", ["getSmApi"]
    ),*/
    pushRoute(item) {
      let item_path = item.path;
      item_path = item_path.slice(1, item.path.length);
      //this.mini = true
      //this.drawer = false
      this.$router.push(item_path);
    },
    actualRoute(path) {
      let getRoute = _.find(this.items, { path });
      if (_.isUndefined(getRoute)) {
        getRoute = {
          color: "",
          icon: "",
          name: "",
        };
      }
      return getRoute;
    },
  },
};
</script>

<style lang='scss'>
.card--flex-toolbar {
  margin-top: 0px;
  margin-bottom: 0px;
}

.body {
  //overscroll-behavior-y: contain !important;
  overflow-y: auto !important; // override Vuetify's default style
}

.html {
  //overscroll-behavior-y: contain !important;
  overflow-y: auto !important; // override Vuetify's default style
}
body {
  //overscroll-behavior-y: contain !important;
  overflow-y: auto !important; // override Vuetify's default style
}
html {
  //overscroll-behavior-y: contain !important;
  overflow-y: auto !important; // override Vuetify's default style
}

#app {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  background-color: #000000;
}

</style>

