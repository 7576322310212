import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import user from "./modules/user";
import sm_api from "./modules/sm_api";
import mesh from "./modules/mesh";
import flowchart from "./modules/flowchart";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    drawer: false
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    // SET_DRAWER(state, _drawer) {
    //   state.drawer = _drawer;
    // }
  },
  // actions: {
  //   setDrawer({ commit }, newValue) {
  //     commit("SET_DRAWER", newValue);
  //   },
  // },

  modules: {
    auth,
    user,
    sm_api,
    mesh,
    flowchart
  }
});
