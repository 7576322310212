export async function fromBase64(data, filename) {
    const base64Slice = new Buffer.from(data, 'base64');
    let file = new File([base64Slice], filename, { type: "model/stl" });
    return file;
}

export function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}