/**********************************************************************
 *  
 *  Copyright (C) Key Concept e.U.
 *
 *  The copyright to the computer program(s) herein is the property
 *  of Key Concept. The program(s) may be used and/or copied only with
 *  the written permission of Key Concept or in accordance with the
 *  terms and conditions stipulated in the agreement/contract under
 *  which the program(s) have been supplied.
 *
 ***********************************************************************/

//export default class graph {

function UndirectedWeightedEdge(weight, value) {
  this.weight = weight;
  this.value  = value;
}

export default class UndirectedWeightedGraph {
  adjacency = new Map();

  addEdge = (u, v, weight, value) => {
    if (!this.adjacency.has(u)) {
      this.adjacency.set(u, new Map());
    }

    if (!this.adjacency.has(v)) {
      this.adjacency.set(v, new Map());
    }

    const edge = new UndirectedWeightedEdge(weight, value);
    this.adjacency.get(u).set(v, edge);
    this.adjacency.get(v).set(u, edge);
  }

  edgesOf = (v) => {
    return this.adjacency.get(v);
  }

  vertices = () => {
    return this.adjacency.keys();
  }

  /**
   * Dijkstra's algorithm to calculate all shortest paths from vertex v.
   * 
   * @return [for each vertex w shortest distance from v to w (if any),
   *   for each vertex w the vertex preceding w on shortest path from
   *   v to w (if any)]
   */
  shortestPaths = (v) => {
    const D = new Map();
    const P = new Map();

    if (!this.adjacency.has(v)) {
      return [D, P];
    }

    const Q = new Set(this.vertices());
    D.set(v, 0);

    while (Q.size > 0) {
      let u = undefined;
      let least = Number.POSITIVE_INFINITY;
      for (let q of Q) {
        const d = D.get(q);
        if ((d !== undefined) && (d < least)) {
          least = d;
          u = q;
        }
      }

      if (u === undefined) {
        return [D, P];
      }

      Q.delete(u);

      for (let [w, e] of this.edgesOf(u).entries()) {
        if (Q.has(w)) {
          const alt = D.get(u) + e.weight;
          const d = D.get(w);
          if (d === undefined || (alt < d)) {
            D.set(w, alt);
            P.set(w, u);
          }
        }
      }
    }

    return [D, P]; 
  }

  /**
   * @return [vertices on shortest path, distance] or null if no path exists
   */
  shortestPath = (u, v) => {
    const [D, P] = this.shortestPaths(u);
    if (!D.has(u) || !D.has(v)) {
      return null; /* No path from u to v */
    }

    const path = [];
    let w = v;
    while (w !== u) {
      path.push(w);
      w = P.get(w);
    }

    path.push(u);
    return [path.reverse(), D.get(v)];
  }

}

