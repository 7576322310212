import Vue from "vue";
import VueResource from "vue-resource";
import { API_ROOT } from "../config";

Vue.use(VueResource);

// Vue.http.options.crossOrigin = true
// Vue.http.options.credentials = true

/*if (process.env.NODE_ENV === "production") {
  throw new Error("Missing API root");
  // Vue.http.options.root = ...
}*/

Vue.http.interceptors.push((request, next) => {
  request.headers = request.headers || {};
  request.headers.set("Authorization", localStorage.getItem("Authorization"));
  next(response => {
    if (response.status === 401) {
      if (!window.location.pathname === "/login")
        window.location.pathname = "/login";
    }
  });
});

export const AuthResource = Vue.resource(API_ROOT + "auth");
export const SignUpResource = Vue.resource(API_ROOT + "signup");
export const ActivateAccountResource = Vue.resource(
  API_ROOT + "activate/{token}"
);
export const MySelfResource = Vue.resource(API_ROOT + "myself");
export const ForgotPasswordResource = Vue.resource(
  API_ROOT + "forgot_password"
);
export const ResetPasswordResource = Vue.resource(
  API_ROOT + "reset_password{/token}"
);
export const UserReadResource = Vue.resource(API_ROOT + "user/{id}");
export const UserResource = Vue.resource(API_ROOT + "user");

export const SmApiResource = Vue.resource(API_ROOT + "sm_api");
export const SaveDeviceCoordinatesResource = Vue.resource(API_ROOT + "sm_api/saveDeviceCoordinates/{id}");

export const MeshResource = Vue.resource(API_ROOT + "mesh");
export const MeshReadResource = Vue.resource(API_ROOT + "mesh/{id}");
export const MeshCreateResource = Vue.resource(API_ROOT + "mesh");
export const MeshDeleteResource = Vue.resource(API_ROOT + "mesh/{id}");
export const MeshDeactivateResource = Vue.resource(API_ROOT + "mesh/deactivate/{id}");
export const MeshUpdateResource = Vue.resource(API_ROOT + "mesh/{id}");

export const FlowchartResource = Vue.resource(API_ROOT + "flowchart");
export const FlowchartCreateResource = Vue.resource(API_ROOT + "flowchart");
export const FlowchartDeleteResource = Vue.resource(API_ROOT + "flowchart/{id}");
export const FlowchartUpdateResource = Vue.resource(API_ROOT + "flowchart/{id}");