const REQUEST_SMAPI = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_SMAPI = (state, { body }) => {
  state.status = "success";
  state.Global = body.globalvars;
  state.Locations = body.locations;
  state.Persons = body.persons;
  state.Devices = body.devices;
};

const FAILURE_REQUEST_SMAPI = state => {
  state.status = "failure";
};

const REQUEST_SAVEDEVICECOORDINATES = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_SAVEDEVICECOORDINATES = (state, { body }) => {
  state.status = "success";
};

const FAILURE_REQUEST_SAVEDEVICECOORDINATES = state => {
  state.status = "failure";
};

export default {
  REQUEST_SMAPI,
  SUCCESS_REQUEST_SMAPI,
  FAILURE_REQUEST_SMAPI,

  REQUEST_SAVEDEVICECOORDINATES,
  SUCCESS_REQUEST_SAVEDEVICECOORDINATES,
  FAILURE_REQUEST_SAVEDEVICECOORDINATES
};
