<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex style="max-width:500px">
        <v-card flat color="transparent">
          <v-card-text>
            <v-card dark>
              <v-card-title class="title">Login</v-card-title>
              <v-card-text>
                <v-alert type="error" :value="status === 'failure'">E-Mail or password incorrect.</v-alert>
              </v-card-text>
              <v-card-text>
                <v-form v-model="valid" style="width:100%;height:100%;">
                  <v-text-field
                    :rules="emailRule"
                    required
                    id="email"
                    v-model="email"
                    label="E-Mail"
                  ></v-text-field>
                  <v-text-field
                    type="password"
                    @keydown.enter="authenticate({email, password})"
                    id="password"
                    v-model="password"
                    label="Password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  :disabled="!valid"
                  outlined
                  color="primary"
                  :loading="loading"
                  @click="authenticate({email, password})"
                >Login</v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <p align="center">
                <br />
                <router-link :to="{name: 'resetPassword'}">{{$t("Forgot password?")}}</router-link>
                <br />
                <br />
                <router-link
                  :to="{name: 'signUp'}"
                >{{$t("You don't have an account yet and you want to sign up?")}}</router-link>
                <br />
                <br />
              </p>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { emailRule } from "../utils/rules";

export default {
  name: "Login",
  data() {
    return {
      password: "",
      email: "",
      valid: false,
      emailValid: false,
      emailRule
    };
  },
  methods: {
    ...mapActions("auth", ["authenticate"])
  },
  computed: {
    ...mapState({
      status: ({ auth }) => auth.status,
      loading: state => state.loading
    })
  },
  watch: {
    status(val) {
      if (val === "success") {
        this.$router.push({ name: "home" });
      }
    }
  }
};
</script>
