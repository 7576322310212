import Vue from 'vue'
import Vuetify from 'vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
	theme: {
		dark: true,
    themes: {
      light: {
        primary: '#00AAAA', 
        secondary: '#00bec5',
        anchor: '#8c9eff'
			},
			dark: {
        primary: '#00AAAA',
        secondary: '#00bec5',
        anchor: '#8c9eff'
      }
    }
  },
  icons: {
    iconfont: 'md',
  },
}

export default new Vuetify(opts)

