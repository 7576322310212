import api from "../../../api";

export default {
  async getFlowcharts({ commit }) {
    commit("REQUEST_FLOWCHARTS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_FLOWCHARTS",
        await api.getFlowcharts()
      );
    } catch (err) {
      commit("FAILURE_REQUEST_FLOWCHARTS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteFlowchart({ commit }, id) {
    commit("REQUEST_DELETE_FLOWCHART");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_FLOWCHART",
        await api.deleteFlowchart(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DELETE_FLOWCHART");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateFlowchart({ commit }, item ) {
    commit("REQUEST_UPDATE_FLOWCHART");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_FLOWCHART",
        await api.updateFlowchart(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_FLOWCHART");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createFlowchart({ commit }, item ) {
    commit("REQUEST_CREATE_FLOWCHART");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_FLOWCHART",
        await api.createFlowchart( item )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_FLOWCHART");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
