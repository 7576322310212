const REQUEST_FLOWCHARTS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_FLOWCHARTS = (state, { body }) => {
  state.Flowcharts = body;
  state.status = "success";
};

const FAILURE_REQUEST_FLOWCHARTS = state => {
  state.status = "failure";
};


const REQUEST_DELETE_FLOWCHART = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_FLOWCHART = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_FLOWCHART = state => {
  state.statusDelete = "failure";
};


const REQUEST_UPDATE_FLOWCHART = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_FLOWCHART = (state, {body}) => {
  state.statusUpdate = "success";
  state.Flowchart = body;
};

const FAILURE_REQUEST_UPDATE_FLOWCHART = state => {
  state.statusUpdate = "failure";
};


const REQUEST_CREATE_FLOWCHART = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_FLOWCHART = (state, {body}) => {
  state.statusCreate = "success";
  state.Flowchart = body;
};

const FAILURE_REQUEST_CREATE_FLOWCHART = state => {
  state.statusCreate = "failure";
};


export default {
  REQUEST_FLOWCHARTS,
  SUCCESS_REQUEST_FLOWCHARTS,
  FAILURE_REQUEST_FLOWCHARTS,
  REQUEST_CREATE_FLOWCHART,
  SUCCESS_REQUEST_CREATE_FLOWCHART,
  FAILURE_REQUEST_CREATE_FLOWCHART,
  REQUEST_DELETE_FLOWCHART,
  SUCCESS_REQUEST_DELETE_FLOWCHART,
  FAILURE_REQUEST_DELETE_FLOWCHART,
  REQUEST_UPDATE_FLOWCHART,
  SUCCESS_REQUEST_UPDATE_FLOWCHART,
  FAILURE_REQUEST_UPDATE_FLOWCHART
 
};
