<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex style="max-width:500px">
        <v-card v-if="status === 'success'" flat dark>
          <v-alert type="info" :value="status === 'success'">Account created</v-alert>
          <v-card-text
            class="text-xs-center"
          >Thank you for signing up. You will receive a verification e-mail.</v-card-text>
        </v-card>
        <v-card v-else flat dark>
          <v-card-text>
            <v-card>
              <v-card-title class="title">{{$t("Sign Up")}}</v-card-title>
              <v-card-text>
                <v-alert type="error" :value="status === 'failure'">Something went wrong</v-alert>
                <v-alert type="info" :value="status === 'success'">Account created</v-alert>
              </v-card-text>
              <v-card-text>
                <v-form v-model="valid" style="width:100%;height:100%;">
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="displayname"
                    label="Angezeigter Kurzname"
                  ></v-text-field>
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="fullname"
                    label="Vor- und Nachname"
                  ></v-text-field>
                  <v-text-field :rules="emailRule" required v-model="email" label="E-Mail"></v-text-field>
                  <v-text-field
                    type="password"
                    :rules="requiredRule"
                    required
                    v-model="password"
                    label="Passwort"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  rounded
                  :disabled="!valid"
                  color="primary"
                  @click="signUp({email, password, displayname, fullname})"
                >{{$t("Sign Up")}}</v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <p align="center">
                <br />
                <router-link :to="{name: 'login'}">{{$t("Back to Login")}}</router-link>
                <br />
                <br />
              </p>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { requiredRule, emailRule } from "../utils/rules";

export default {
  name: "SignUp",
  data() {
    return {
      displayname: "",
      fullname: "",
      password: "",
      email: "",
      valid: false,
      requiredRule,
      emailRule
    };
  },
  methods: {
    ...mapActions("user", ["signUp"])
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status
    })
  }
};
</script>

<style scoped>
</style>
