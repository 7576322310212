import Vue from "vue";
import Router from "vue-router";
import LoginComponent from "./views/Login.vue";
import SignUpComponent from "./views/SignUp.vue";
import AccountActivation from "./views/AccountActivation.vue";
import ResetPasswordComponent from "./views/ResetPassword.vue";
import Home from "./views/Home.vue";
import Mesh from "./views/Mesh.vue";
import Dxf from "./views/Dxf.vue";
//import Ar from "./views/Ar.vue";
import FlowChartComponent from "./views/FlowChart.vue";
import Three from "./views/Three.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: {
        name: "login",
      },
    },
    {
      path: "/login",
      name: "login",
      component: LoginComponent,
    },
    {
      path: "/flowchart",
      name: "flowchart",
      icon: "insert_chart",
      color: "red darken-2",
      component: FlowChartComponent,
    },

    {
      path: "/sign-up",
      name: "signUp",
      component: SignUpComponent,
    },
    {
      path: "/activate-account/:token?",
      component: AccountActivation,
      name: "accountActivation",
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: ResetPasswordComponent,
    },
    {
      path: "/confirm-password-reset/:token?",
      name: "resetPasswordConfirm",
      component: ResetPasswordComponent,
    },
    {
      path: "/home",
      icon: "insert_chart",
      color: "red darken-2",
      name: "home",
      component: Home,
    },
    {
      path: "/mesh",
      icon: "insert_chart",
      color: "red darken-2",
      name: "mesh",
      component: Mesh,
    },
    {
      path: "/dxf",
      icon: "insert_chart",
      color: "red darken-2",
      name: "dxf",
      component: Dxf,
    },
    {
      path: "/three",
      icon: "insert_chart",
      color: "blue darken-2",
      name: "three",
      component: Three,
    },
    // {
    //   path: "/ar",
    //   icon: "insert_chart",
    //   color: "red darken-2",
    //   name: "ar",
    //   component: Ar,
    // },
  ],
});
