const REQUEST_AUTH = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_AUTH = (state, { body }) => {
  state.status = "success";
  localStorage.setItem("Authorization", body.token);
};

const FAILURE_REQUEST_AUTH = state => {
  state.status = "failure";
};

const REQUEST_MYSELF = state => {
  state.statusMySelf = "fetching";
};

const SUCCESS_REQUEST_MYSELF = (state, { body }) => {
  state.statusMySelf = "success";
  state.mySelf = body;
};

const FAILURE_REQUEST_MYSELF = state => {
  state.statusMySelf = "failure";
};

export default {
  REQUEST_AUTH,
  SUCCESS_REQUEST_AUTH,
  FAILURE_REQUEST_AUTH,
  REQUEST_MYSELF,
  SUCCESS_REQUEST_MYSELF,
  FAILURE_REQUEST_MYSELF
};