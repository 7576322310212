<template>
  <div class="dxf">
    <v-card flat dark>
      <v-row>
        <v-col>
         
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";

import { fromBase64, toBase64 } from "../functions/base64_helpers";

  // import fs from 'fs'
import { join } from 'path'
import Helper from "dxf"

export default {
  name: "dxf",
  components: {},
  data() {
    return {
      

      snackbar: false,
      snackbar_text: "",
    };
  },
  async mounted() {
    const helper = new Helper(fs.readFileSync('./test/resources/Ceco.NET-Architecture-Tm-53.dxf', 'utf-8'));
  },
  computed: {


  },
  methods: {
   
  },
  watch: {

  },
};
</script>