<template>
  <div class="flowChart">
    <v-card flat light>
      <v-row>
        <v-col cols="4" md="3" lg="2" class="ma-0 mt-2">
          <v-select
            dense
            solo
            background-color="secondary"
            v-model="flowchart"
            :items="flowcharts"
            item-text="name"
            color="white"
            v-on:change="change"
            return-object
          >
          </v-select>
         </v-col>
        <v-col cols="3" md="2" lg="1" >
          <v-text-field class="ma-0 mt-0" v-model="name" label="Name"></v-text-field>
        </v-col>
        <v-col cols="3" md="2" lg="1" >
          <v-text-field
            class="mt-0"
            v-model="category"
            label="Category"
          ></v-text-field>
        </v-col>
        <v-col cols="3" md="2" lg="1" class="ma-0 mt-2">
          <v-btn color="orange" @click="save">Save New</v-btn>
        </v-col>
        <v-col cols="3" md="2" lg="1" class="ma-0 mt-2">
          <v-btn color="blue" @click="update">Update</v-btn>
        </v-col>
        <v-col cols="3" md="2" lg="1" class="ma-0 mt-2">
          <v-btn color="red" @click="del">Delete</v-btn>
        </v-col>
         <v-col cols="3" md="2" lg="1" class="ma-0 mt-2">
          <v-btn color="green" @click="run"><v-icon>mdi-play</v-icon>Run</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-textarea
            class="caption"
            v-model="code"
            style="width: 100%"
            rows="11"
            outlined
          >
          </v-textarea>
        </v-col>
        <v-col cols="12" md="12">
          <div id="canvas"></div>
        </v-col>
      </v-row>
     
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<!--<script src="http://cdnjs.cloudflare.com/ajax/libs/raphael/2.3.0/raphael.min.js"></script>
<script src="http://cdnjs.cloudflare.com/ajax/libs/jquery/1.11.0/jquery.min.js"></script>
 <script src="http://flowchart.js.org/flowchart-latest.js"></script>
 <script src="../release/flowchart.min.js"></script> -->

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
//import api from "../api";

import { fromBase64, toBase64 } from "../functions/base64_helpers";

const flowchart = require("flowchart.js");
// import fs from 'fs'
//import { join } from 'path'
//import Helper from "dxf"

export default {
  name: "flowChart",
  components: {},
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      chart: undefined,
      cd: undefined,
      canvas: undefined,
      code: "",
      category: "",
      name: "",
      flowchart: {},
      flowcharts: []
    };
  },
  async mounted() {
    this.canvas = document.getElementById("canvas");
    await this.getFlowcharts();
    if (this.Flowcharts) {
      this.flowcharts = this.Flowcharts;
      this.flowchart = this.Flowcharts[0];
      this.code = this.Flowcharts[0].code;
    } else {
      this.flowcharts = [];
    }
    
  },
  computed: {
     ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Flowcharts: ({ flowchart }) => flowchart.Flowcharts
    }),
  },
  methods: {
    ...mapActions("flowchart", [
      "getFlowcharts",
      "createFlowchart",
      "updateFlowchart",
      "deleteFlowchart",
    ]),
    change() {
      this.code = this.flowchart.code;
      this.run();
    },
    async del() {
       try {
        await this.deleteFlowchart(this.flowchart._id);
        const idx = _.findIndex(this.flowcharts, {_id: this.flowchart._id})
        this.flowcharts.splice(idx, 1);
        this.flowchart = {}
        this.code = "";
      } catch(err) {
        console.log(err)
      }
    },
    async update() {
      this.flowchart.code = this.code;
      try {
        await this.updateFlowchart(this.flowchart)
      } catch(err) {
        console.log(err)
      }
    },
    async save() {
      try {
        let obj = {};
        obj.name = this.name;
        obj.code = this.code;
        obj.category = this.category;
        await this.createFlowchart(obj);
        this.flowcharts.push(obj);
        this.flowchart = obj;
      } catch(err) {
        console.log(err)
      }
    },
    run() {
      //console.log("RUN")
      //var code = this.cd.value;
      //console.log(this.code);
      if (this.chart) {
        this.chart.clean();
      }

      this.chart = flowchart.parse(this.code);

      //console.log(this.chart)
      // let code2 =
      //   "st=>start: 伍|past:>http://www.google.com[blank]\n" +
      //   "e=>end: Ende:>http://www.google.com\n" +
      //   "op1=>operation: My Operation|past\n" +
      //   "op2=>operation: Stuff|current\n" +
      //   "sub1=>subroutine: My Subroutine|invalid\n" +
      //   "cond=>condition: Yes or No?|approved:>http://www.google.com\n" +
      //   "c2=>condition: Good idea|rejected\n" +
      //   "io=>inputoutput: catch something...|request\n" +
      //   "st->op1(right)->cond\n" +
      //   "cond(yes, right)->c2\n" +
      //   "cond(no)->sub1(left)->op1\n";
      // console.log(code2);
      this.chart = flowchart.parse(this.code);
      try {
        this.chart.drawSVG(this.canvas, {
          // 'x': 30,
          // 'y': 50,
          "line-width": 3,
          maxWidth: 3, //ensures the flowcharts fits within a certian width
          "line-length": 50,
          "text-margin": 10,
          "font-size": 14,
          font: "normal",
          "font-family": "Helvetica",
          "font-weight": "normal",
          "font-color": "black",
          "line-color": "black",
          "element-color": "black",
          fill: "white",
          "yes-text": "yes",
          "no-text": "no",
          "arrow-end": "block",
          scale: 1,
          symbols: {
            start: {
              "font-color": "red",
              "element-color": "green",
              fill: "yellow",
            },
            end: {
              class: "end-element",
            },
          },
          flowstate: {
            past: { fill: "#CCCCCC", "font-size": 12 },
            current: {
              fill: "yellow",
              "font-color": "red",
              "font-weight": "bold",
            },
            future: { fill: "#FFFF99" },
            request: { fill: "blue" },
            invalid: { fill: "#444444" },
            approved: {
              fill: "#58C4A3",
              "font-size": 12,
              "yes-text": "APPROVED",
              "no-text": "n/a",
            },
            rejected: {
              fill: "#C45879",
              "font-size": 12,
              "yes-text": "n/a",
              "no-text": "REJECTED",
            },
          },
        });
      } catch (err) {
        console.log(err);
      }

      //console.log("Finish")

      // $("[id^=sub1]").click(function () {
      //   alert("info here");
      // });
    },

    // myFunction(event, node) {
    //   console.log("You just clicked this node:", node);
    // }
  },
  watch: {},
};
</script>